import Loading from '../images/loading.gif'
import React from 'react'

/**
 *
 * @param {string} endpoint
 * @param {string} metodo GET, POST, PUT, DELETE
 * @param {object} body
 * @returns
 */
function api(endpoint, metodo, body) {
  const url = `https://api-classificados.estadao.com.br/${endpoint}`
  const method = metodo || 'GET'
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(body)
  }
  return fetch(url, options)
}

export default api

/**
 * Retorna os tipos de anúncios
 * @param {int} caderno_id
 * @returns
 */
export const apiGetUfs = async () => {
  const dados = await api('site/ufs', 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetCidades = async (uf) => {
  const dados = await api('site/cidades?uf=' + uf, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetRegioes = async (cidadeId) => {
  const dados = await api('site/regioes?cidade_id=' + cidadeId, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetBairros = async (regiaoId) => {
  const dados = await api('site/bairros?regiao_id=' + regiaoId, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetAnos = async () => {
  const dados = await api('site/anos', 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetPerfis = async () => {
  const dados = await api('site/perfis-vagas', 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetEscolaridade = async () => {
  const dados = await api('site/escolaridades', 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetNiveisHierarquicos = async () => {
  const dados = await api('site/niveis-hierarquicos', 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetOportunidadesRetrancas = async () => {
  const dados = await api('site/oportunidades-retrancas', 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetOportunidadesCategorias = async (id) => {
  const dados = await api('site/oportunidades-categorias/' + id, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetFaixaPreco = async (cadernoId) => {
  const dados = await api('site/faixa-preco?caderno_id=' + cadernoId, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return parseFloat(data.max ?? 0)
    })
  return dados
}

export const apiGetTipos = async (cadernoId) => {
  const dados = await api('site/tipos?caderno_id=' + cadernoId, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetMarcas = async (cadernoId, tipoId) => {
  const dados = await api('site/marcas?caderno_id=' + cadernoId + '&tipo_id=' + tipoId, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetModelos = async (marcaId, tipoId) => {
  const dados = await api('site/modelos?marca_id=' + marcaId + '&tipo_id=' + tipoId, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetSecoes = async (cadernoId) => {
  const dados = await api('site/secoes?caderno_id=' + cadernoId, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetFlush = async () => {
  const dados = await api('site/cache/flush', 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiPostMesage = async (body) => {
  const dados = await api('site/mensagem', 'POST', body)
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiPostMesageResultados = async (body) => {
  const dados = await api('site/anunciar', 'POST', body)
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const apiGetArea = async (cadernoId) => {
  const dados = await api('site/area?caderno_id=' + cadernoId, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return parseFloat(data.max)
    })
  return dados
}

export const apiGetFinalidades = async (cadernoId) => {
  const dados = await api('site/finalidades?caderno_id=' + cadernoId, 'GET')
    .then((response) => response.json())
    .then((data) => {
      return data.data
    })
  return dados
}

export const fetchData = async ({
  setCallFetchData = '',
  setLoadingData = '',
  setResultado = '',
  setTotalPages = '',
  setPage = '',
  jornalId = '',
  cadernoId = '',
  page = '',
  q = '',
  perPage = '',
  total = '',
  uf = '',
  cidadeId = '',
  regiaoId = '',
  bairroId = '',
  tipoId = '',
  marcaId = '',
  modeloId = '',
  ano = '',
  preco = '',
  secao = '',
  perfilVagaId = '',
  nivelHierarquicoId = '',
  escolaridadeId = '',
  finalidadeId = '',
  area = '',
  areaMax = '',
  ats = '',
  retrancaId = '',
  categoriaId = ''
}) => {
  setCallFetchData(false)
  setLoadingData(true)

  if (ano[0] === 1990 && ano[1] === new Date().getFullYear()) {
    ano = ''
  }

  if (preco[0] === 0 && preco[1] === 0) {
    preco = ''
  }

  if (areaMax !== '' && area[0] === 0 && (area[1] === areaMax || area[1] === 0)) {
    area = ''
  }

  const urlData = {
    jornal_id: jornalId,
    caderno_id: cadernoId,
    page,
    q,
    per_page: perPage,
    total,
    uf,
    cidade_id: cidadeId,
    regiao_id: regiaoId,
    bairro_id: bairroId,
    tipo_id: tipoId,
    marca_id: marcaId,
    modelo_id: modeloId,
    faixa_ano: ano ? ano.join(',') : '',
    faixa_preco: preco ? preco.join(',') : '',
    area_min: area ? area[0] : '',
    area_max: area ? area[1] : '',
    secao,
    perfil_vaga_id: perfilVagaId,
    nivel_hierarquico_id: nivelHierarquicoId,
    escolaridade_id: escolaridadeId,
    finalidade_id: finalidadeId,
    secao_id: retrancaId,
    categoria_id: categoriaId,
    ativo: false
  }

  if (ats !== '') {
    if (ats.length === 0) {
      urlData['at'] = ats.join(',')
    } else {
      urlData['at[]'] = ats.join(',')
    }
  }

  const urlParams = new URLSearchParams(urlData)

  const keysForDel = []
  urlParams.forEach((value, key) => {
    if (value === '') {
      keysForDel.push(key)
    }
  })

  keysForDel.forEach((key) => {
    urlParams.delete(key)
  })

  await api('busca/?' + urlParams, 'GET')
    .then((response) => response.json())
    .then((data) => {
      setResultado(data)
      setTotalPages(data.pagination.total_pages)
      setPage(data.pagination.current)
    })
  setLoadingData(false)
}

// get tipos
export const getTipos = async (setTiposLoading, cadernoId, setTipos, setCallFetchData) => {
  setTiposLoading(true)
  const data = await apiGetTipos(cadernoId)
  setTipos(data)
  setTiposLoading(false)
  setCallFetchData(true)
}

export const getMarcas = async (
  setMarcasLoading,
  cadernoId,
  tipoId,
  setMarcas,
  setCallFetchData
) => {
  setMarcasLoading(true)
  const data = await apiGetMarcas(cadernoId, tipoId)
  setMarcas(data)
  setMarcasLoading(false)
  setCallFetchData(true)
}

// get modelos
export const getModelos = async (
  setModelosLoading,
  marcaId,
  tipoId,
  setModelos,
  setCallFetchData
) => {
  setModelosLoading(true)
  const data = await apiGetModelos(marcaId, tipoId)
  setModelos(data)
  setModelosLoading(false)
  setCallFetchData(true)
}

// get anos
export const getAnos = async (setAnosLoading, setAnos) => {
  setAnosLoading(true)
  const data = await apiGetAnos()
  setAnos(data)
  setAnosLoading(false)
}

// get Niveis
export const getNiveisHierarquicos = async (
  setNiveisHierarquicosLoading,
  setNiveisHierarquicos,
  setCallFetchData
) => {
  setNiveisHierarquicosLoading(true)
  const data = await apiGetNiveisHierarquicos()
  const concat = data.cargo.concat(data.hierarquico)
  setNiveisHierarquicos(concat)
  setNiveisHierarquicosLoading(false)
  setCallFetchData(true)
}

export const getEscolaridades = async (
  setEscolaridadesLoading,
  setEscolaridades,
  setCallFetchData
) => {
  setEscolaridadesLoading(true)
  const data = await apiGetEscolaridade()
  setEscolaridades(data)
  setEscolaridadesLoading(false)
  setCallFetchData(true)
}

export const getRetrancas = async (setRetrancasLoading, setRetrancas, setCallFetchData) => {
  setRetrancasLoading(true)
  const data = await apiGetOportunidadesRetrancas()
  setRetrancas(data)
  setRetrancasLoading(false)
  setCallFetchData(true)
}

export const getCategorias = async (
  setCategoriasLoading,
  retrancaId,
  setCategorias,
  setCallFetchData
) => {
  setCategoriasLoading(true)
  const data = await apiGetOportunidadesCategorias(retrancaId)
  setCategorias(data)
  setCategoriasLoading(false)
  setCallFetchData(true)
}

export const getPerfis = async (setPerfisLoading, setPerfis, setCallFetchData) => {
  setPerfisLoading(true)
  const data = await apiGetPerfis()
  setPerfis(data)
  setPerfisLoading(false)
  setCallFetchData(true)
}
export const getEstados = async (setEstadosLoading, setEstados, setCallFetchData) => {
  setEstadosLoading(true)
  const data = await apiGetUfs()
  setEstados(data)
  setEstadosLoading(false)
  setCallFetchData(true)
}
export const getPrecoMax = async (
  setPrecoMaxLoading,
  cadernoId,
  setPrecoMax,
  setPreco,
  setCallFetchData
) => {
  setPrecoMaxLoading(true)
  const data = await apiGetFaixaPreco(cadernoId)
  setPrecoMax(data)
  setPreco([0, data])
  setPrecoMaxLoading(false)
  setCallFetchData(true)
}
export const getAreaMax = async (
  setAreaMaxLoading,
  cadernoId,
  setAreaMax,
  setArea,
  setCallFetchData
) => {
  setAreaMaxLoading(true)
  const data = await apiGetArea(cadernoId)
  setAreaMax(data)
  setArea([0, data])
  setAreaMaxLoading(false)
  setCallFetchData(true)
}

export const getCidades = async (uf, setCidades) => {
  const data = await apiGetCidades(uf)
  setCidades(data)
}

export const getRegioes = async (cidadeId, setRegioes, setCallFetchData) => {
  const data = await apiGetRegioes(cidadeId)
  setRegioes(data)
}

export const getBairros = async (regiaoId, setBairros, setCallFetchData) => {
  const data = await apiGetBairros(regiaoId)
  setBairros(data)
}

export const callAnAt = (setanAtLoading, cadernoId, setAnAt) => {
  const urlData = {
    caderno_id: cadernoId
  }
  getAnAt(urlData, cadernoId, setanAtLoading, setAnAt)
}

export const getAnAt = async (urlData, cadernoId, setanAtLoading, setAnAt) => {
  setanAtLoading(true)
  const urlParams = new URLSearchParams(urlData)
  await api('site/anuncios-atributos?' + urlParams, 'GET')
    .then((response) => response.json())
    .then((data) => {
      setAnAt(data.data)
    })
    .catch((error) => {
      console.log('error: ', error)
      if (cadernoId === 7) {
        setAnAt({
          data: [
            { id: '52', descricao: 'Área Total' },
            { id: '69', descricao: 'Mezanino' },
            { id: '39', descricao: 'Terraço' },
            { id: '83', descricao: 'Telefone' },
            { id: '99', descricao: 'Estacionamento' },
            { id: '34', descricao: 'Piscina' },
            { id: '36', descricao: 'Salão de Festas' },
            { id: '38', descricao: 'Particular' },
            { id: '66', descricao: 'Infraestrutura para Internet' },
            { id: '84', descricao: 'TV a Cabo' },
            { id: '30', descricao: 'Churrasqueira' },
            { id: '31', descricao: 'Elevador' },
            { id: '35', descricao: 'Playground' },
            { id: '81', descricao: 'Salão de Jogos' },
            { id: '100', descricao: 'Varanda Gourmet' },
            { id: '101', descricao: 'Sala de Ginástica' },
            { id: '79', descricao: 'Quintal' },
            { id: '44', descricao: '2 Banheiros' },
            { id: '57', descricao: 'Copa' },
            { id: '82', descricao: 'Sauna' },
            { id: '85', descricao: 'Varanda' },
            { id: '89', descricao: '3 Dormitórios' },
            { id: '41', descricao: '2 Vagas Garagem' },
            { id: '37', descricao: 'Suite' },
            { id: '48', descricao: 'Locação por Temporada' },
            { id: '51', descricao: 'Área de Serviço' },
            { id: '59', descricao: 'Frente ao Mar' },
            { id: '62', descricao: 'Guarita' },
            { id: '88', descricao: '2 Dormitórios' },
            { id: '28', descricao: 'Academia' },
            { id: '50', descricao: 'Ar Condicionado' },
            { id: '67', descricao: 'Interfone' },
            { id: '90', descricao: '4 Dormitórios ou mais' },
            { id: '40', descricao: '1 Vaga Garagem' },
            { id: '87', descricao: '1 Dormitório' },
            { id: '54', descricao: 'Armário Embutido' }
          ]
        })
      } else {
        setAnAt({
          data: [
            { id: '16', descricao: 'Alarme' },
            { id: '24', descricao: 'Rodas Liga Leve' },
            { id: '93', descricao: 'Gasolina' },
            { id: '102', descricao: 'Único dono' },
            { id: '18', descricao: 'Banco de couro' },
            { id: '21', descricao: 'Painel Multimídia' },
            { id: '23', descricao: '4 Portas' },
            { id: '26', descricao: 'Trio Elétrico' },
            { id: '95', descricao: 'Automatizado' },
            { id: '97', descricao: 'Blindado' },
            { id: '15', descricao: 'Air Bag' },
            { id: '17', descricao: 'Ar-condicionado' },
            { id: '19', descricao: 'Dir. Hidráulica' },
            { id: '20', descricao: 'Freios ABS' },
            { id: '25', descricao: 'Sensor Estacionam.' },
            { id: '27', descricao: 'Vidros Elétricos' },
            { id: '94', descricao: 'Automático' },
            { id: '22', descricao: 'Particular' },
            { id: '106', descricao: 'Diesel' },
            { id: '96', descricao: 'Manual' },
            { id: '107', descricao: 'Novo' },
            { id: '103', descricao: 'Cor' },
            { id: '104', descricao: 'Quilometragem' }
          ]
        })
      }
    })
  setanAtLoading(false)
}

export const callDestaques = (jornalId, cadernoId, setDestaquesLoading, setDestaques) => {
  const urlData = {
    jornal_id: jornalId,
    caderno_id: cadernoId,
    per_page: 10,
    destaque: true
    // ativo: false
  }

  getDestaques(urlData, setDestaquesLoading, setDestaques)
}

export const getDestaques = async (urlData, setDestaquesLoading, setDestaques) => {
  setDestaquesLoading(true)
  const urlParams = new URLSearchParams(urlData)
  await api('busca/?' + urlParams, 'GET')
    .then((response) => response.json())
    .then((data) => {
      const filterData = data.data.filter((item) => item.destaque === '1')
      setDestaques(filterData)
    })
  setDestaquesLoading(false)
}

export const changeTipo = (
  nome,
  setTipoSelecao,
  setTipoId,
  setMarcas,
  setMarcaId,
  setModelos,
  setModeloId,
  setCallFetchData
) => {
  setTipoSelecao(nome)
  setTipoId('')
  setMarcas(false)
  setMarcaId('')
  setModelos(false)
  setModeloId('')

  setCallFetchData(true)
}

export const tipoSelected = (
  value,
  tipoId,
  setTipoId,
  setMarcas,
  setMarcaId,
  setModelos,
  setModeloId,
  setCallFetchData
) => {
  setTipoId(tipoId === value ? '' : value)
  setMarcas(false)
  setMarcaId('')
  setModelos(false)
  setModeloId('')

  setCallFetchData(true)
}

export const marcaSelected = (value, setMarcaId, setModelos, setModeloId, setCallFetchData) => {
  setMarcaId(value)
  setModelos(false)
  setModeloId('')

  setCallFetchData(true)
}

export const changeQ = (value, setQ, setCallFetchData) => {
  setQ(value)

  setCallFetchData(true)
}

export const modeloSelected = (value, setModeloId, setCallFetchData) => {
  setModeloId(value)

  setCallFetchData(true)
}

export const getSecoes = async (setSecoesLoading, cadernoId, setSecoes, setCallFetchData) => {
  setSecoesLoading(true)
  const data = await apiGetSecoes(cadernoId)
  setSecoes(data)
  setSecoesLoading(false)
  setCallFetchData(true)
}

export const changeSecao = (nome, setTipoSelecao, setSecao, setCallFetchData) => {
  setTipoSelecao(nome)
  setSecao('')

  setCallFetchData(true)
}

export const getFinalidades = async (
  setFinalidadesLoading,
  cadernoId,
  setFinalidades,
  setCallFetchData
) => {
  setFinalidadesLoading(true)
  const data = await apiGetFinalidades(cadernoId)
  setFinalidades(data)
  setFinalidadesLoading(false)
  setCallFetchData(true)
}

export const sendEmail = (body, setSend) => {
  apiPostMesage(body)
  setSend(true)
}

export const sendEmailResultados = (body, setNome, setTelefone, setEmail, setTipo, setSend) => {
  apiPostMesageResultados(body)
  setSend(true)
  setNome('')
  setTelefone('')
  setEmail('')
  setTipo('')
}

export const finalidadeSelected = (value, setFinalidadeId, setCallFetchData) => {
  setFinalidadeId(value)
  setCallFetchData(true)
}

export const tipoImovelSelected = (value, setTipoId, setCallFetchData) => {
  setTipoId(value)
  setCallFetchData(true)
}

export function scrollToResults() {
  const rect = document.querySelector('.menu-search .contentList').getBoundingClientRect()
  window.scrollTo(0, rect.top - 100)
}

export async function getDetalhe(id, setLoading, setDados, setCadernoId) {
  setLoading(true)
  await api('site/detalhe/' + id, 'GET')
    .then((response) => response.json())
    .then((data) => {
      setDados(data)
      setCadernoId(parseInt(data.caderno_id))
    })
  setLoading(false)
}

export const pagination = (value, setPage, setCallFetchData) => {
  setPage(value)
  setCallFetchData(true)
}

export const real = (valor) => {
  // Tratamento de preço
  const real = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0
  })

  return real.format(valor)
}

export const realSemMoeda = (valor) => {
  // Tratamento de preço
  const real = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 0
  })

  return real.format(valor)
}

export const loadHtml = () => {
  return (
    <div className="results text-center">
      <img width="30px" className="loading" src={Loading} />
    </div>
  )
}
