import React from 'react'

function ButtonTop({ bgColor, text, color, onClick }) {
  return (
    <a
      onClick={onClick}
      style={{ backgroundColor: bgColor || '', color: color || '' }}
      className="buttonTop"
    >
      {text}
    </a>
  )
}

export default ButtonTop
