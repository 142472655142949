import React from 'react'

const BanheirosIcon = (props) => {
  const { className, style, onClick, width = 21, height = 18, color = '#206395' } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 18"
      fill="none"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <g>
        <path
          d="M20.5328 9.55598C20.5328 9.55598 20.4524 9.73201 20.3989 9.80745C20.2383 10.0338 19.9973 10.1344 19.7029 10.1092C19.3816 10.0841 19.1675 9.88289 19.0604 9.60627C18.8195 8.95245 18.5785 8.29862 18.3376 7.64479C18.177 7.21728 18.0164 6.76463 17.8022 6.33713C17.829 6.51316 17.8557 6.68919 17.9093 6.86522C18.2037 8.32376 18.4982 9.75716 18.8195 11.2157C18.8998 11.6432 18.6856 11.8695 18.2841 11.8192C18.1502 11.8192 18.0163 11.8192 17.8825 11.8192V12.0707C17.8825 13.6801 17.8825 15.2644 17.8825 16.8738C17.8825 17.4522 17.4542 17.8294 16.8652 17.7791C16.4101 17.7288 16.0889 17.3768 16.0889 16.9241C16.0889 16.4463 16.0889 15.9937 16.0889 15.5159C16.0889 14.3591 16.0889 13.2275 16.0889 12.0707V11.8695H15.7141V12.0959C15.7141 13.6801 15.7141 15.2644 15.7141 16.8487C15.7141 17.0247 15.6873 17.2259 15.607 17.4019C15.4464 17.7288 15.0181 17.9049 14.6165 17.8043C14.2417 17.7288 13.974 17.4271 13.9473 17.0499C13.9473 16.9744 13.9473 16.8738 13.9473 16.7984C13.9473 15.2393 13.9473 13.6801 13.9473 12.121V11.8695C13.7867 11.8695 13.626 11.8695 13.4654 11.8695C13.0639 11.8695 12.9032 11.6684 12.9835 11.3163C13.3316 9.68172 13.6528 8.07229 14.0008 6.43772C14.0008 6.43772 14.0008 6.38742 14.0008 6.36227C14.0008 6.41257 13.9473 6.46286 13.9473 6.48801C13.5457 7.5442 13.1442 8.60038 12.7426 9.63142C12.6088 10.0086 12.2608 10.2098 11.9127 10.1344C11.4309 10.0589 11.1632 9.63142 11.3238 9.17877C11.5112 8.65068 11.6986 8.14773 11.9127 7.61964C12.2875 6.63889 12.6355 5.633 13.0371 4.65226C13.2245 4.19961 13.519 3.79725 14.0276 3.59607C14.1882 3.52063 14.3756 3.47034 14.563 3.47034C15.4999 3.47034 16.4101 3.47034 17.3471 3.47034C17.9896 3.47034 18.4447 3.8224 18.7391 4.35049C18.9265 4.70255 19.0604 5.07976 19.221 5.45697C19.7029 6.71434 20.158 7.99685 20.6398 9.27936C20.6398 9.27936 20.6398 9.27936 20.6398 9.30451V9.60627L20.5328 9.55598Z"
          fill="#206395"
        />
        <path
          d="M4.04227 10.2098H3.64071C3.64071 10.2098 3.64071 10.3607 3.64071 10.4361C3.64071 12.5988 3.64071 14.7615 3.64071 16.9241C3.64071 17.4019 3.39978 17.7037 2.97146 17.8294C2.43605 17.9803 1.87388 17.6031 1.82034 17.075C1.82034 16.9996 1.82034 16.9241 1.82034 16.8235C1.82034 12.976 1.82034 9.12848 1.82034 5.28094C1.82034 5.15521 1.90065 4.95403 1.68649 4.95403C1.44555 4.95403 1.52587 5.15521 1.52587 5.30609C1.52587 6.94066 1.52587 8.55009 1.52587 10.1847C1.52587 10.5116 1.41878 10.7631 1.12431 10.9391C0.642448 11.1906 0.0802738 10.8888 0.0267334 10.3858C0.0267334 10.3355 0.0267334 10.2853 0.0267334 10.2098C0.0267334 8.52494 0.0267334 6.86522 0.0535036 5.18035C0.0535036 4.32535 0.562138 3.69666 1.41878 3.47034C1.60618 3.42004 1.79357 3.42004 1.98096 3.42004C3.23916 3.42004 4.49736 3.42004 5.75556 3.42004C6.93345 3.42004 7.68302 4.12417 7.68302 5.2558C7.68302 6.94066 7.68302 8.60038 7.68302 10.2853C7.68302 10.8133 7.20115 11.1403 6.69252 10.9894C6.39805 10.8888 6.23742 10.7128 6.18388 10.4361C6.18388 10.3355 6.18388 10.235 6.18388 10.1344C6.18388 8.52494 6.18388 6.91552 6.18388 5.30609C6.18388 5.18035 6.23742 4.97918 6.02326 4.97918C5.8091 4.97918 5.86264 5.18035 5.86264 5.30609C5.86264 9.17877 5.86264 13.0515 5.86264 16.9493C5.86264 17.4271 5.62171 17.754 5.19339 17.8546C4.65798 18.0055 4.09581 17.6283 4.06904 17.1253C4.06904 17.0499 4.06904 16.9744 4.06904 16.8738C4.06904 14.7363 4.06904 12.624 4.06904 10.4864C4.06904 10.411 4.06904 10.3355 4.06904 10.235L4.04227 10.2098Z"
          fill="#206395"
        />
        <path
          d="M10.1727 8.92726C10.1727 11.3665 10.1727 13.7807 10.1727 16.22C10.1727 16.3206 10.1727 16.4211 10.1727 16.5217C10.1191 16.7732 9.85145 16.9241 9.58374 16.9241C9.31604 16.9241 9.10188 16.7481 9.04834 16.4966C9.04834 16.4211 9.04834 16.3457 9.04834 16.2451C9.04834 11.3665 9.04834 6.51312 9.04834 1.63454C9.04834 1.5591 9.04834 1.45851 9.04834 1.38307C9.10188 1.10645 9.36958 0.955566 9.66405 0.955566C9.93176 0.955566 10.1727 1.18189 10.1727 1.43336C10.1727 1.50881 10.1727 1.58425 10.1727 1.65969C10.1727 4.07383 10.1727 6.51312 10.1727 8.92726Z"
          fill="#206395"
        />
        <path
          d="M3.82818 0C4.68483 0 5.40763 0.653829 5.40763 1.45854C5.40763 2.26326 4.7116 2.91709 3.85496 2.91709C2.99831 2.91709 2.27551 2.23811 2.27551 1.4334C2.27551 0.653829 2.97154 0 3.82818 0Z"
          fill="#206395"
        />
        <path
          d="M14.2953 1.48372C14.2953 0.679006 15.0181 0.0251762 15.8747 0.0503235C16.7314 0.0754708 17.4274 0.754448 17.4006 1.53401C17.3739 2.33873 16.6779 2.96741 15.8212 2.96741C14.9646 2.96741 14.2685 2.28843 14.2953 1.48372Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default BanheirosIcon
