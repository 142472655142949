import React from 'react'

import IconAuto from './../images/icon_auto.svg'
import IconAutoWhite from './../images/icon_auto_white.svg'
import IconCarrer from './../images/icon_carrer.png'
import IconCarrerWhite from './../images/icon_carrer_white.svg'
import IconHouse from './../images/icon_house.svg'
import IconHouseWhite from './../images/icon_house_white.svg'
import IconOpportunity from './../images/icon_oppportunity.svg'
import IconOpportunityWhite from './../images/icon_opportunity_white.svg'

import LogoAuto from '../images/logo-auto.svg'
import LogoCarreira from '../images/logo-carreira-e-empregos.svg'
import LogoImoveis from '../images/logo-imoveis.svg'
import LogoOportunidades from '../images/logo-oportunidade.svg'

export const prefix = '/'

export const config = {
  autos: {
    caderno: 2,
    logo: LogoAuto,
    header_url: prefix + 'autos',
    slug: 'autos',
    top_search_title: 'Classificados Auto e Serviços',
    top_search_subtitle: ''
  },
  imoveis: {
    caderno: 7,
    logo: LogoImoveis,
    header_url: prefix + 'imoveis',
    slug: 'imoveis',
    top_search_title: 'Classificados Estadão Imóveis',
    top_search_subtitle: 'O imóvel que você buscava acaba de te encontrar'
  },
  carreiras: {
    caderno: 9,
    logo: LogoCarreira,
    header_url: prefix + 'carreiras-e-empregos',
    slug: 'carreiras-e-empregos',
    top_search_title: 'Classificados Estadão Carreiras & Emprego',
    top_search_subtitle: ''
  },
  oportunidades: {
    caderno: 10,
    logo: LogoOportunidades,
    header_url: prefix + 'oportunidades-e-leiloes',
    slug: 'oportunidades-e-leiloes',
    top_search_title: 'Classificados Estadão Oportunidades & Leilões',
    top_search_subtitle: ''
  }
}

export const homeButtons = [
  {
    slug: 'autos',
    icon: IconAuto,
    iconHover: IconAutoWhite,
    text: 'Autos e Serviços'
  },
  {
    slug: 'carreiras-e-empregos',
    icon: IconCarrer,
    iconHover: IconCarrerWhite,
    text: 'Carreiras e Empregos'
  },
  {
    slug: 'imoveis',
    icon: IconHouse,
    iconHover: IconHouseWhite,
    text: 'Imóveis'
  },
  {
    slug: 'oportunidades-e-leiloes',
    icon: IconOpportunity,
    iconHover: IconOpportunityWhite,
    text: 'Oportunidades e Leilões'
  }
]

export const footerMenu = [
  {
    href: 'https://www.estadao.com.br/termo-de-uso/',
    title: 'Termo de uso',
    rel: false
  },
  {
    href: 'https://www.estadao.com.br/codigo-etica/codigo-de-etica.pdf',
    title: 'Código de ética',
    rel: true
  },
  {
    href: 'https://www.estadao.com.br/politica-anticorrupcao/politica-anticorrupcao.pdf',
    title: 'Política Anticorrupção',
    rel: true
  },
  {
    href: 'https://www.estadao.com.br/relatorio-da-administracao/',
    title: 'Demonstrações contábeis',
    rel: true
  },
  {
    href: 'https://www.estadao.com.br/fale-conosco/',
    title: 'Fale conosco',
    rel: false
  },
  {
    href: 'https://bluestudio.estadao.com.br',
    title: 'Publicidade',
    rel: true
  },
  {
    href: 'https://oespgrafica.com.br',
    title: 'Gráfica',
    rel: true
  },
  {
    href: 'https://jobs.kenoby.com/estadao',
    title: 'Trabalhe conosco',
    rel: true
  },
  {
    href: 'https://www.estadao.com.br/tudo-sobre/focas',
    title: 'Curso de jornalismo',
    rel: true
  }
]

export const footerSocialMedia = [
  {
    href: 'https://www.facebook.com/estadao',
    content: <i className="fa-brands fa-facebook-f"></i>
  },
  {
    href: 'https://twitter.com/estadao/',
    content: <i className="fa-brands fa-twitter "></i>
  },
  {
    href: 'https://www.instagram.com/estadao/',
    content: <i className="fa-brands fa-instagram "></i>
  },
  {
    href: 'https://www.youtube.com/@estadao',
    content: <i className="fa-brands fa-youtube "></i>
  },
  {
    href: 'https://meu.estadao.com.br/newsletter',
    content: (
      <span>
        <i className="fa-regular fa-envelope"></i> Receba as newsletters do Estadão
      </span>
    )
  }
]

export const footerAcesso = [
  {
    href: 'https://acesso.estadao.com.br/login/estadao-jornal-digital',
    title: 'Jornal de hoje'
  },
  {
    href: 'https://meu.estadao.com.br',
    title: 'meuEstadão'
  }
]

export const autosAtributos = {
  caracs: [
    { id: 15, descricao: 'Air Bag', tipo: 'Característica' },
    { id: 16, descricao: 'Alarme', tipo: 'Característica' },
    { id: 17, descricao: 'Ar-condicionado', tipo: 'Característica' },
    { id: 18, descricao: 'Banco de couro', tipo: 'Característica' },
    { id: 19, descricao: 'Dir. Hidráulica', tipo: 'Característica' },
    { id: 20, descricao: 'Freios ABS', tipo: 'Característica' },
    { id: 21, descricao: 'Painel Multimídia', tipo: 'Característica' },
    { id: 22, descricao: 'Particular', tipo: 'Característica' },
    { id: 23, descricao: '4 Portas', tipo: 'Característica' },
    { id: 24, descricao: 'Rodas Liga Leve', tipo: 'Característica' },
    { id: 25, descricao: 'Sensor Estacionam.', tipo: 'Característica' },
    { id: 26, descricao: 'Trio Elétrico', tipo: 'Característica' },
    { id: 27, descricao: 'Vidros Elétricos', tipo: 'Característica' },
    { id: 97, descricao: 'Blindado', tipo: 'Característica' },
    { id: 102, descricao: 'Único dono', tipo: 'Característica' },
    { id: 103, descricao: 'Cor', tipo: 'Característica' },
    { id: 107, descricao: 'Novo', tipo: 'Característica' },
    { id: 109, descricao: 'Elétrico', tipo: 'Característica' }
  ],
  combustivel: [
    { id: 91, descricao: 'Álcool', tipo: 'Combustível' },
    { id: 92, descricao: 'Flex', tipo: 'Combustível' },
    { id: 93, descricao: 'Gasolina', tipo: 'Combustível' },
    { id: 106, descricao: 'Diesel', tipo: 'Combustível' },
    { id: 111, descricao: 'GNV', tipo: 'Câmbio' }
  ],
  cambio: [
    { id: 94, descricao: 'Automático', tipo: 'Câmbio' },
    { id: 95, descricao: 'Automatizado', tipo: 'Câmbio' },
    { id: 96, descricao: 'Manual', tipo: 'Câmbio' },
    { id: 110, descricao: 'Híbrido', tipo: 'Câmbio' }
  ]
}

export const imoveisAtributos = {
  dorms: [
    { id: 87, descricao: '1', tipo: 'Dormitórios' },
    { id: 88, descricao: '2', tipo: 'Dormitórios' },
    { id: 89, descricao: '3', tipo: 'Dormitórios' },
    { id: 90, descricao: '4+', tipo: 'Dormitórios' }
  ],
  garagens: [
    { id: 40, descricao: '1', tipo: 'Garagens' },
    { id: 41, descricao: '2', tipo: 'Garagens' },
    { id: 42, descricao: '3+', tipo: 'Garagens' }
  ],
  caracs: [
    { id: 28, descricao: 'Academia', tipo: 'Característica' },
    { id: 50, descricao: 'Ar Condicionado', tipo: 'Característica' },
    { id: 51, descricao: 'Área de Serviço', tipo: 'Característica' },
    { id: 54, descricao: 'Armário Embutido', tipo: 'Característica' },
    { id: 30, descricao: 'Churrasqueira', tipo: 'Característica' },
    { id: 56, descricao: 'Closet', tipo: 'Característica' },
    { id: 57, descricao: 'Copa', tipo: 'Característica' },
    { id: 58, descricao: 'Despensa', tipo: 'Característica' },
    { id: 31, descricao: 'Elevador', tipo: 'Característica' },
    { id: 99, descricao: 'Estacionamento', tipo: 'Característica' },
    { id: 59, descricao: 'Frente ao Mar', tipo: 'Característica' },
    { id: 62, descricao: 'Guarita', tipo: 'Característica' },
    { id: 63, descricao: 'Heliponto', tipo: 'Característica' },
    { id: 64, descricao: 'Hidromassagem', tipo: 'Característica' },
    { id: 65, descricao: 'Home Theater', tipo: 'Característica' },
    { id: 66, descricao: 'Infraestrutura para Internet', tipo: 'Característica' },
    { id: 67, descricao: 'Interfone', tipo: 'Característica' },
    { id: 68, descricao: 'Jardim', tipo: 'Característica' },
    { id: 32, descricao: 'Lareira', tipo: 'Característica' },
    { id: 48, descricao: 'Locação por Temporada', tipo: 'Característica' },
    { id: 69, descricao: 'Mezanino', tipo: 'Característica' },
    { id: 33, descricao: 'Mobiliado', tipo: 'Característica' },
    { id: 47, descricao: 'Orientação Solar Norte', tipo: 'Característica' },
    { id: 38, descricao: 'Particular', tipo: 'Característica' },
    { id: 34, descricao: 'Piscina', tipo: 'Característica' },
    { id: 35, descricao: 'Playground', tipo: 'Característica' },
    { id: 77, descricao: 'Quadra Poliesportiva', tipo: 'Característica' },
    { id: 78, descricao: 'Quadra de Tênis', tipo: 'Característica' },
    { id: 79, descricao: 'Quintal', tipo: 'Característica' },
    { id: 36, descricao: 'Salão de Festas', tipo: 'Característica' },
    { id: 29, descricao: 'Segurança', tipo: 'Característica' },
    { id: 37, descricao: 'Suite', tipo: 'Característica' },
    { id: 39, descricao: 'Terraço', tipo: 'Característica' },
    { id: 101, descricao: 'Sala de Ginástica', tipo: 'Característica' },
    { id: 80, descricao: 'Sala de Jantar', tipo: 'Característica' },
    { id: 81, descricao: 'Salão de Jogos', tipo: 'Característica' },
    { id: 98, descricao: 'Studio', tipo: 'Característica' },
    { id: 82, descricao: 'Sauna', tipo: 'Característica' },
    { id: 83, descricao: 'Telefone', tipo: 'Característica' },
    { id: 84, descricao: 'TV a Cabo', tipo: 'Característica' },
    { id: 85, descricao: 'Varanda', tipo: 'Característica' },
    { id: 100, descricao: 'Varanda Gourmet', tipo: 'Característica' },
    { id: 86, descricao: 'WC Empregada', tipo: 'Característica' },
    { id: 108, descricao: 'Próximo ao metrô', tipo: 'Característica' }
  ],
  quant: [
    { id: '52', descricao: 'Área Total' },
    { id: '60', descricao: 'Latitude' },
    { id: '61', descricao: 'Longitude' },
    { id: '73', descricao: 'Quantidade de Andares' },
    { id: '99', descricao: 'Estacionamento' }
  ]
}

export const viewData = {
  2: {
    logo: LogoAuto,
    descricao: 'Autos e Serviços',
    slug: 'autos',
    description_title: 'Descrição do auto',
    title_form: 'Fale com o anunciante',
    show_price: true,
    show_photos: true,
    tax_type: 'ipva',
    sub_localizacao: false
  },
  7: {
    logo: LogoImoveis,
    descricao: 'Imóveis',
    slug: 'imoveis',
    description_title: 'Descrição do imóvel',
    title_form: 'Fale com o proprietário',
    show_price: true,
    show_photos: true,
    tax_type: 'iptu',
    sub_localizacao: true,
    sub_anunciante: false
  },
  9: {
    logo: LogoCarreira,
    descricao: 'Carreiras e Empregos',
    slug: 'carreiras-e-empregos',
    description_title: 'Descrição',
    title_form: 'Fale com o anunciante',
    show_price: false,
    show_photos: false,
    tax_type: false,
    sub_localizacao: false,
    sub_anunciante: true
  },
  10: {
    logo: LogoOportunidades,
    descricao: 'Jornal do Carro',
    slug: 'oportunidades-e-leiloes',
    description_title: 'Descrição',
    title_form: 'Fale com o anunciante',
    show_price: false,
    show_photos: false,
    tax_type: false,
    sub_localizacao: false,
    sub_anunciante: true
  }
}
