import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { AdvertisingSlot } from 'react-advertising'
import Slider from 'react-slick'
import TimeAgo from 'javascript-time-ago'
import pt from 'javascript-time-ago/locale/pt-PT.json'
import useModal from './useModal'

import { sendEmail, getDetalhe, real } from '../api/api'

import Header from '../components/Header'

import SampleNextArrowIcon from '../images/SampleNextArrowIcon'
import SamplePrevArrowIcon from '../images/SamplePrevArrowIcon'
import AreaIcon from '../images/AreaIcon'
import AndaresIcon from '../images/AndaresIcon'
import VagasIcon from '../images/VagasIcon'
import PhoneIcon from '../images/PhoneIcon'
import BanheirosIcon from '../images/BanheirosIcon'
import BoxSimilar from '../components/BoxSimilar'
import Footer from '../components/Footer'

import { prefix, viewData } from '../utils/variables'

TimeAgo.addDefaultLocale(pt)

function View() {
  const { id } = useParams()

  const [dados, setDados] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cadernoId, setCadernoId] = useState(false)

  const [nome, setNome] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [send, setSend] = useState(false)
  if (dados === false && loading === false) {
    getDetalhe(id, setLoading, setDados, setCadernoId)
  }

  const data = viewData[cadernoId]

  let timeAgo
  let area, andares, garagem, banheiros
  let garagem1, garagem2, garagem3
  let banheiros1, banheiros2, banheiros3, banheiros4
  let cabecalho
  let sub
  if (dados !== false) {
    timeAgo = new TimeAgo('pt-PT')

    if (data.sub_localizacao) {
      cabecalho = dados.retranca.tipo

      sub = dados.uf
    } else if (dados.retranca) {
      cabecalho = dados.retranca.tipo
    }
  }

  let atributos
  const caracteristicas = []
  const info = []
  switch (cadernoId) {
    case 2:
      sub = dados.retranca?.retranca + '/' + dados.ano
      break
    case 7:
      area = dados.atributos.find((i) => i.id === '52')
      area = area.valor !== '' ? area.valor ?? 0 : 0
      area += ' m²'

      andares = dados.atributos.find((i) => i.id === '73')
      andares = andares.valor !== '' ? andares.valor ?? 0 : 0
      if (andares === 1) {
        andares += ' andar'
      } else {
        andares += ' andares'
      }
      garagem = '0 garagens'
      garagem1 = dados.atributos.find((i) => i.id === '40')
      garagem2 = dados.atributos.find((i) => i.id === '41')
      garagem3 = dados.atributos.find((i) => i.id === '42')
      if (garagem1.valor === 't') {
        garagem = '1 garagem'
      }
      if (garagem2.valor === 't') {
        garagem = '2 garagens'
      }
      if (garagem3.valor === 't') {
        garagem = '3+ garagens'
      }
      banheiros = '0 banheiros'
      banheiros1 = dados.atributos.find((i) => i.id === '43')
      banheiros2 = dados.atributos.find((i) => i.id === '44')
      banheiros3 = dados.atributos.find((i) => i.id === '45')
      banheiros4 = dados.atributos.find((i) => i.id === '46')
      if (banheiros1.valor === 't') {
        banheiros = '1 banheiro'
      }
      if (banheiros2.valor === 't') {
        banheiros = '2 banheiros'
      }
      if (banheiros3.valor === 't') {
        banheiros = '3 banheiros'
      }
      if (banheiros4.valor === 't') {
        banheiros = '3+ banheiros'
      }

      atributos = (
        <div className="atributos">
          <span>
            <AreaIcon /> {area}
          </span>
          <span>
            <AndaresIcon /> {andares}
          </span>
          <span>
            <VagasIcon /> {garagem}
          </span>
          <span>
            <BanheirosIcon /> {banheiros}
          </span>
        </div>
      )
      break
    default:
      if (dados.atributos) {
        dados.atributos.forEach((at) => {
          if (at.valor === 't') {
            caracteristicas.push(<li>{at.descricao}</li>)
          } else if (at.valor !== null) {
            if (at.valor === '') {
              if (at.tipo === 'text') {
                at.valor = '--'
              } else if (at.tipo === 'number' || at.tipo === 'int' || at.tipo === 'float') {
                at.valor = 0
              }
              info.push(
                <li>
                  <small>{at.descricao}</small>
                  <p>{at.valor}</p>
                </li>
              )
            }
          }
        })
        atributos = (
          <div className="atributos">
            {info.length > 0 && <ul className="info">{info}</ul>}
            {caracteristicas.length > 0 && (
              <div className="caracteristicas">
                <h3>Adicionais</h3>
                <ul className="caracteristicas">{caracteristicas}</ul>
              </div>
            )}
          </div>
        )
      }
      break
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrowIcon color="#555" />,
    prevArrow: <SamplePrevArrowIcon color="#555" />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }
  const { isOpen, openModal, closeModal } = useModal()

  const handleVerTelefonesClick = () => {
    openModal()
  }

  function enviarMensagem() {
    const body = {
      anuncio_id: dados.id,
      nome,
      telefone,
      email,
      mensagem
    }

    sendEmail(body, setSend)

    setTimeout(() => {
      setSend(false)
    }, 3000)
  }

  if (!dados) {
    return <>Carregando anúncio...</>
  } else {
    return (
      <div className={'App view ' + data.slug}>
        <Header logo={data.logo} to={prefix + data.slug} />
        {dados.anexos && dados.anexos.length > 0 && (
          <Slider {...settings}>
            {dados.anexos.map((image, index) => (
              <div key={'detalhe-image-' + index}>
                <div
                  className="photo"
                  style={{
                    backgroundImage: 'url(' + image.url.detalhe + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                ></div>
              </div>
            ))}
          </Slider>
        )}
        <div className="container container-info">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={prefix}>Início</a>
              </li>
              <li className="breadcrumb-item">
                <a href={prefix + data.slug}>{data.descricao}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ref: {dados.cod_anuncio}
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-sm-7">
              <h5>{cabecalho}</h5>
              <h1>{dados.titulo}</h1>
              <p className="sub">{sub}</p>
              <div className="time">Publicado {timeAgo.format(new Date(dados.enriquecido_em))}</div>
              {atributos}
              <h2>{data.description_title}</h2>
              <hr />
              <div className="descricao">{dados.descricao_complementar}</div>
              <a href={prefix + data.slug} className="btn btn-voltar">
                Voltar
              </a>
            </div>
            <div className="col-sm-5">
              {dados.status === 'A' ? (
                <aside>
                  {data.show_price && (
                    <div className="price">
                      {dados.preco && dados.preco !== '0.00' && (
                        <>
                          <label>Valor:</label>
                          <h3>{real(parseFloat(dados.preco))}</h3>
                        </>
                      )}
                      <button className="btn" onClick={handleVerTelefonesClick}>
                        Ver telefones
                      </button>
                      {isOpen && (
                        <div className="popup">
                          <p className="phone-popup">
                            <PhoneIcon /> Telefone: {dados.anunciante.telefone_anuncio}
                          </p>
                          <button className="btn-phone-closed" onClick={closeModal}>
                            Fechar
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="form">
                    <h3>{data.title_form}</h3>
                    <input
                      placeholder="Nome"
                      onChange={(e) => setNome(e.target.value)}
                      value={nome}
                    />
                    <input
                      placeholder="Telefone"
                      onChange={(e) => setTelefone(e.target.value)}
                      value={telefone}
                    />
                    <input
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <textarea
                      placeholder="Olá, tenho interesse no anúncio. Vi esta oferta no Portal de Classificados do Estadão. Aguardo o contato. Obrigado!"
                      onChange={(e) => setMensagem(e.target.value)}
                      value={mensagem}
                    />
                    <button className="btn btn-enviar" onClick={() => enviarMensagem()}>
                      Enviar
                    </button>
                    {send && <div className="success">Mensagem enviada!</div>}
                  </div>
                </aside>
              ) : (
                <aside>
                  <div className="price">
                    <p>Anúncio indisponível</p>
                  </div>
                </aside>
              )}
            </div>
          </div>
        </div>

        <div className="container content">
          <div className="row">
            <div className="col-12 text-center">
              <AdvertisingSlot id="bannerAnuncioView-01" />
            </div>
          </div>
        </div>

        {dados.similares && (
          <div className="container container-anuncios-similares" style={{ clear: 'both' }}>
            <div className="anuncios-similares">
              <h5>Anúncios Similares</h5>
              <hr />
              <div className="anuncios">
                {dados.similares.map((item, index) => (
                  <BoxSimilar data={data} item={item} key={index} />
                ))}
              </div>
            </div>
          </div>
        )}

        <Footer blueStudio={false} />
      </div>
    )
  }
}

export default View
