import React, { useState } from 'react'
import TxtResultado from '../images/txt_resultado.svg'
import chatIcon from '../images/chatIcon.svg'
import { Link } from 'react-router-dom'
import { sendEmailResultados } from '../api/api'

function BoxResultado() {
  const [nome, setNome] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [tipo, setTipo] = useState('')
  const [send, setSend] = useState(false)

  const enviarMensagem = () => {
    const body = {
      nome,
      telefone,
      email,
      tipo
    }

    sendEmailResultados(body, setNome, setTelefone, setEmail, setTipo, setSend)

    setTimeout(() => {
      setSend(false)
    }, 3000)
  }

  return (
    <div className="anunciar content">
      <div className="container">
        <div className="logo">
          <img alt="" src={TxtResultado} />
        </div>
        <div className="info">
          <p>
            Se você quer anunciar seu imóvel ou seu carro, anunciar uma vaga da sua empresa ou fazer
            bons negócios aqui é o seu lugar.
          </p>
          <p className="destaque">
            Anuncie e descubra porque que a diferença é que os Classificados do Estadão funcionam.
          </p>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Telefone"
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <select className="form-control" value={tipo} onChange={(e) => setTipo(e.target.value)}>
              <option value="">O que deseja anunciar?</option>
              <option value="Imoveis">Imóveis</option>
              <option value="Automovel">Automóvel</option>
              <option value="Vaga">Vaga</option>
              <option value="Oportunidade">Oportunidade</option>
            </select>
          </div>
          <button className="btn btn-success btn-block" onClick={() => enviarMensagem()}>
            Enviar
          </button>
          <div className={'success-message' + (send ? ' active' : '')}>
            Mensagem enviada com sucesso! Em breve entraremos em contato.
          </div>
          <div className="canais">
            <p>Ou utilize nossos canais de atendimento</p>
            <div className="destaque">
              <div className="chat">
                <Link
                  onClick={() =>
                    window.open(
                      'https://www4.directtalk.com.br/chat31/?idd=DD8F001381686002665D',
                      'Anuncie',
                      'width=461,height=560'
                    )
                  }
                  className="btn btn-primary btn-block"
                >
                  <img className="" src={chatIcon} />
                  CHAT
                </Link>
              </div>
              <div className="telefone">
                <a href="tel:+551138552001">
                  <img
                    src={
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE0SURBVHgBrVSBjcMgDLR+AkZghIzQTdoNwgb5nyC/ASNkBH6D/AYZId2AGvVQXUoKJTnJIjJwZzvGRALee83m/B0jHQUmM2wriKPAmfaACZQgm9k6+ILQQnvABBbEQ+K/7I4eBNPG3rIn+i+s1439HzadZlUNROfe7Ifaz9SAEPkfW7dB/MuLYvunFjBBj7qfEr8RbamoBaLtXOKf4G8jFkRjGn34PuSl4tnnoh+PeqkDiIzwKXRTEO6oFQmRFn4t/CbxO4yMcmaYKysuqIyAR6nkPFrEei4JxJniMpnFfxCn5yDu1ImI+j9lIIgsW78RWBSxNQKL/Ac1iCUrHTLV9Xzc0bgz1R6OqU6lLHym40oCSpTJo+anzLlv7Pf0KZCFFSIr6muR1UuXtYpcQDgnQjqeuwGi0ly6XlNWeQAAAABJRU5ErkJggg=='
                    }
                  />
                  <span>(11) 3855-2001</span>
                </a>{' '}
                |{' '}
                <a href="tel:08000552001">
                  <span>0800-055-2001</span>
                </a>
              </div>
              <div className="whatsapp">
                <a
                  href="https://api.whatsapp.com/send?phone=5511991812018"
                  className="btn btn-primary btn-block"
                >
                  <img
                    src={
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHWSURBVHgBlZRNKMRBGMZnUSSKg49EbSm5OEkOvg5cEMrBmRwccHP0nYODk1ZLUspBtAcXX6XWFg7OSjaslNAuu9nTosYz9Ywdu//d/Xvr1+z7zjPPzM68/YWwCCllIZgAxyAk4/EOTsAQKBV2AsLRBJNU8QLG0hk5wJqx4B7MgRZQok4E2sA8CBi6VbXWytBFwRcXFabZPBtMGabuRMGQMTkubAav55vrRnQxDzywOM2aE+yDPhumi1wbBLmqMMhCABRRdMGa14ZhkYw/4qAqeJgsGyIz6myYLlG7rRI/kw5DoHc8t3zBZMN+/S+zkFey/mFoXByfHQ6HFJnjkWOxco/Qvd7YUfXdK+uzmdygaaY2rE4YZr1aC3CqIAZ1BW9gRpmCfC4eBp3yb5+W/54UE+t0d1vs3GC01C3YMh4rpk5G3QZrmyrp0MfVbZNgqnryQCbHE6jifJS1Ab0oxkKbSH1PvWAXeDk6WV/RL6yFVcau5eIfwbvV0aWL3SxcM6/gpTvTGJWBHcNsUs/lgCb+Vq1yg7GG+SdyD0Yf8FNbC1pBJyigbgpdsWDudiiTIyIzx5XkK5uhPqp3GKPgFFyCI+yoPvXt+N0DGkW8z0LgDOxB4xMW8QO4A9veEMNOiwAAAABJRU5ErkJggg=='
                    }
                    alt=""
                  />
                  WHATSAPP
                </a>
              </div>
              <div className="email">
                <a href="mailto:anunciar.classificados@estadao.com">
                  <img
                    src={
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAARCAYAAADZsVyDAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADpSURBVHgB1ZThDYJADIV7xv8wgm7ABjoCG+gIbgAbgBPgBrgBbuAIsoE6wflqeqQSBJHzhy95OeCuX0tpIGvtAq6sP5XMNHxBRBGcwzeaphDewTVJloQ8iVkMdOALl08TBcZaWA3YKfsmAWJCONegphXuFSTjZgQ0hq/ipN2KRA7xhBTyrOir3r5OU+XOdoJV0FYqZ6Ud0FRVGbf23oNVRZlqT6Q/juyFHXH94FYCB2Od4VXP+Sd4TgMyxtRYltwerAHu9/SBBsEqwYFGaEY/0v+BuccnmOeW/2x3mqaAWfDRjVNp/ali5gPhl+pCsGFjYgAAAABJRU5ErkJggg=='
                    }
                  />
                  <span>anunciar.classificados@estadao.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoxResultado
