import React from 'react'
import { logVersionInfo } from 'react-advertising'
import { footerAcesso, footerMenu, footerSocialMedia } from '../utils/variables'
import BlueStudio from './BlueStudio'

function Footer(props) {
  const { blueStudio } = props

  logVersionInfo()
  return (
    <footer className="content">
      {(blueStudio === undefined || blueStudio) && <BlueStudio />}
      <section className="footer__infos">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-3 logo-footer">
              <img
                width="100%"
                src="https://statics.estadao.com.br/s2016/portal/logos/logo-estadao-cavalo.svg"
              />
              <p>Grupo Estado © 1995-2022. </p>
              <p>Todos os direitos reservados </p>
            </div>
            <div className="col-12 col-sm-6 menu-footer">
              <div className="row">
                <div className="col-12">
                  <nav>
                    <ul className="list">
                      {footerMenu.map((item, index) => (
                        <li key={'footer-menu-' + index}>
                          <a
                            href={item.href}
                            title={item.title}
                            target={item.rel ? '_blank' : ''}
                            rel={item.rel ? 'nofollow noopener noreferrer' : ''}
                          >
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
                <div className="col-12 footer-socialmedia">
                  <p>Siga o Estadão</p>
                  {footerSocialMedia.map((media, index) => (
                    <a
                      key={'footer-media-' + index}
                      className={
                        index === footerSocialMedia.length - 1
                          ? 'btn-newsletter'
                          : 'btn btn-primary btn-social'
                      }
                      href={media.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {media.content}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3">
              <div className="row">
                <div className="col-6">
                  <img
                    width="100%"
                    src="https://img.estadao.com.br/fotos3/162x269/capa_site_162(184).jpg"
                  />
                </div>
                <div className="col-6">
                  <ul className="list">
                    {footerAcesso.map((acesso, index) => (
                      <li key={'footer-acesso-' + index}>
                        <a
                          href={acesso.href}
                          title={acesso.title}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          {acesso.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <a
                    className="btn-assine"
                    href="https://ofertas.estadao.com.br/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>
                      <b>
                        <i className="fa-solid fa-newspaper"></i> Assine Estadão
                      </b>
                    </p>
                    <p>Receba o Estadão em casa</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
