import React, { useEffect, useState } from 'react'
import { Pagination } from '@mui/material'
import { AdvertisingSlot } from 'react-advertising'

import { config } from '../utils/variables'

import {
  fetchData,
  getTipos,
  getModelos,
  getMarcas,
  getEstados,
  getPrecoMax,
  callDestaques,
  pagination,
  getFinalidades,
  getAreaMax,
  getSecoes,
  getNiveisHierarquicos,
  getPerfis,
  getEscolaridades,
  getRetrancas,
  loadHtml,
  callAnAt
} from '../api/api'

import Box from '../components/Box'
import BoxResultado from '../components/BoxResultado'
import Destaques from '../components/Destaques'
import Filters from '../components/Filters'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Localizacao from '../components/Localizacao'
import TopSearch from '../components/TopSearch'
import SideFilter from '../components/SideFilter'

import CloseIcon from '../images/closeIcon.svg'
import FilterIcon from '../images/FilterIcon'

function Editoria({ editoria }) {
  const editoriaConfig = config[editoria]
  const cadernoId = editoriaConfig.caderno
  const topSearchSubtitle = editoriaConfig.top_search_subtitle
  const logo = config[editoria].logo

  const [page, setPage] = useState(1)
  const [perPage] = useState(6)
  const [totalPages, setTotalPages] = useState(1)
  const [q, setQ] = useState('')
  const [total] = useState(0)
  const [saveCaderno, setSaveCaderno] = useState(cadernoId)

  const [tipos, setTipos] = useState(false)
  const [tiposLoading, setTiposLoading] = useState(false)
  const [tipoId, setTipoId] = useState('')

  const [marcas, setMarcas] = useState(false)
  const [marcasLoading, setMarcasLoading] = useState(false)
  const [marcaId, setMarcaId] = useState('')

  const [modelos, setModelos] = useState(false)
  const [modelosLoading, setModelosLoading] = useState(false)
  const [modeloId, setModeloId] = useState('')

  const [ano, setAno] = useState([1990, new Date().getFullYear()])

  const [precoMax, setPrecoMax] = useState(false)
  const [precoMaxLoading, setPrecoMaxLoading] = useState(false)

  const [preco, setPreco] = useState([0, 0])

  const [secoes, setSecoes] = useState(false)
  const [secoesLoading, setSecoesLoading] = useState(false)
  const [secao, setSecao] = useState('')

  const [perfisVaga, setPerfisVaga] = useState(false)
  const [perfisVagaLoading, setPerfisVagaLoading] = useState(false)
  const [perfilVagaId, setPerfilVagaId] = useState('')

  const [niveisHierarquicos, setNiveisHierarquicos] = useState(false)
  const [niveisHierarquicosLoading, setNiveisHierarquicosLoading] = useState(false)
  const [nivelHierarquicoId, setNivelHierarquicoId] = useState('')

  const [escolaridades, setEscolaridades] = useState(false)
  const [escolaridadesLoading, setEscolaridadesLoading] = useState(false)
  const [escolaridadeId, setEscolaridadeId] = useState('')

  const [ats, setAts] = useState([])

  const [finalidades, setFinalidades] = useState(false)
  const [finalidadesLoading, setFinalidadesLoading] = useState(false)
  const [finalidadeId, setFinalidadeId] = useState('')

  const [area, setArea] = useState([0, 0])
  const [areaMaxLoading, setAreaMaxLoading] = useState(false)
  const [areaMax, setAreaMax] = useState(false)

  const [retrancas, setRetrancas] = useState(false)
  const [retrancasLoading, setRetrancasLoading] = useState(false)
  const [retrancaId, setRetrancaId] = useState('')

  const [categorias, setCategorias] = useState(false)
  const [, setCategoriasLoading] = useState(false)
  const [categoriaId, setCategoriaId] = useState('')

  const [ufs, setUfs] = useState(false)
  const [ufsLoading, setUfsLoading] = useState(false)
  const [uf, setUf] = useState('')

  const [cidades, setCidades] = useState(false)
  const [cidadeId, setCidadeId] = useState('')

  const [regioes, setRegioes] = useState(false)
  const [regiaoId, setRegiaoId] = useState('')

  const [bairros, setBairros] = useState(false)
  const [bairroId, setBairroId] = useState('')

  const [anAt, setAnAt] = useState(false)
  const [anAtLoading, setAnAtLoading] = useState(false)

  const [destaques, setDestaques] = useState(false)
  const [destaquesLoading, setDestaquesLoading] = useState(false)

  const [loadingData, setLoadingData] = useState(true)
  const [resultado, setResultado] = useState([])
  const [callFetchData, setCallFetchData] = useState(true)

  const [filterSelected, setFilterSelected] = useState(false)
  const [filterLoading, setFilterLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(false)

  const jornalId = 1 // sempre será 1 = Estadão

  useEffect(() => {
    if (showFilter) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [showFilter])

  if (callFetchData) {
    setFilterLoading(false)
    setPage(1)
    setTotalPages(1)

    fetchData({
      setCallFetchData,
      setLoadingData,
      setResultado,
      setTotalPages,
      setPage,
      jornalId,
      cadernoId,
      page,
      q,
      perPage,
      total,
      uf,
      cidadeId,
      regiaoId,
      bairroId,
      tipoId,
      marcaId,
      modeloId,
      ano,
      preco,
      secao,
      perfilVagaId,
      nivelHierarquicoId,
      escolaridadeId,
      finalidadeId,
      area,
      areaMax,
      ats,
      retrancaId,
      categoriaId
    })
  }

  let topSearchProps
  let sideFilterProps
  let filterProps
  const data = []
  switch (cadernoId) {
    case 2:
      if (cadernoId !== saveCaderno) {
        setSaveCaderno(cadernoId)
        setDestaques(false)
        setTipos(false)
        setTipoId('')
        setMarcas(false)
        setMarcaId('')
        setModelos(false)
        setModeloId('')
        setPreco([0, 0])
        setPrecoMax(false)
        setCidades(false)
        setCidadeId('')
        setRegioes(false)
        setRegiaoId('')
        setBairros(false)
        setBairroId('')
      }

      if (tipos === false && tiposLoading === false) {
        getTipos(setTiposLoading, cadernoId, setTipos, setCallFetchData)
      }

      if (tipoId !== '' && marcas === false && marcasLoading === false) {
        getMarcas(setMarcasLoading, cadernoId, tipoId, setMarcas, setCallFetchData)
      }

      if (marcaId !== '' && modelos === false && modelosLoading === false) {
        getModelos(setModelosLoading, marcaId, tipoId, setModelos, setCallFetchData)
      }

      if (ufs === false && ufsLoading === false) {
        getEstados(setUfsLoading, setUfs, setCallFetchData)
      }

      if (precoMax === false && precoMaxLoading === false) {
        getPrecoMax(setPrecoMaxLoading, cadernoId, setPrecoMax, setPreco, setCallFetchData)
      }

      if (anAt === false && anAtLoading === false) {
        callAnAt(setAnAtLoading, cadernoId, setAnAt)
      }

      if (tipos !== false) {
        topSearchProps = {
          cadernoId,
          tipos,
          tipoId,
          setTipoId,
          marcas,
          setMarcas,
          marcaId,
          setMarcaId,
          setModelos,
          setModeloId,
          q,
          setQ,
          setCallFetchData
        }
      }

      sideFilterProps = {
        cadernoId,
        showFilter,
        setShowFilter,
        tipos,
        tipoId,
        setTipoId,
        marcas,
        setMarcas,
        marcaId,
        setMarcaId,
        modelos,
        setModelos,
        modeloId,
        setModeloId,
        ano,
        setAno,
        preco,
        setPreco,
        precoMax,
        anAt,
        ats,
        setAts,
        setCallFetchData
      }

      filterProps = {
        cadernoId,
        tipos,
        tipoId,
        setTipoId,
        marcas,
        setMarcas,
        marcaId,
        setMarcaId,
        modelos,
        setModelos,
        modeloId,
        setModeloId,
        ano,
        precoMax,
        setAno,
        preco,
        setPreco,
        ufs,
        uf,
        setUf,
        cidades,
        setCidades,
        cidadeId,
        setCidadeId,
        regioes,
        setRegioes,
        regiaoId,
        setRegiaoId,
        bairros,
        setBairros,
        bairroId,
        setBairroId,
        ats,
        setAts,
        setCallFetchData,
        setFilterSelected,
        filterLoading,
        setFilterLoading
      }

      resultado.data?.map((item, index) => data.push(<Box item={item} key={index} />))
      break
    case 7:
      if (cadernoId !== saveCaderno) {
        setSaveCaderno(cadernoId)
        setDestaques(false)
        setFinalidades(false)
        setFinalidadeId('')
        setTipos(false)
        setTipoId('')
        setArea([0, 0])
        setAreaMax(false)
        setPreco([0, 0])
        setPrecoMax(false)
        setCidades(false)
        setCidadeId('')
        setRegioes(false)
        setRegiaoId('')
        setBairros(false)
        setBairroId('')
      }

      if (finalidades === false && finalidadesLoading === false) {
        getFinalidades(setFinalidadesLoading, cadernoId, setFinalidades, setCallFetchData)
      }

      if (tipos === false && tiposLoading === false) {
        getTipos(setTiposLoading, cadernoId, setTipos, setCallFetchData)
      }

      if (ufs === false && ufsLoading === false) {
        getEstados(setUfsLoading, setUfs, setCallFetchData)
      }

      if (areaMax === false && areaMaxLoading === false) {
        getAreaMax(setAreaMaxLoading, cadernoId, setAreaMax, setArea, setCallFetchData)
      }

      if (precoMax === false && precoMaxLoading === false) {
        getPrecoMax(setPrecoMaxLoading, cadernoId, setPrecoMax, setPreco, setCallFetchData)
      }

      if (anAt === false && anAtLoading === false) {
        callAnAt(setAnAtLoading, cadernoId, setAnAt)
      }

      if (finalidades !== false && tipos !== false) {
        topSearchProps = {
          cadernoId,
          finalidades,
          finalidadeId,
          setFinalidadeId,
          tipos,
          tipoId,
          setTipoId,
          q,
          setQ,
          setCallFetchData
        }
      }

      sideFilterProps = {
        cadernoId,
        finalidades,
        finalidadeId,
        setFinalidadeId,
        tipos,
        tipoId,
        setTipoId,
        area,
        setArea,
        areaMax,
        preco,
        setPreco,
        precoMax,
        ats,
        setAts,
        anAt,
        setCallFetchData
      }

      filterProps = {
        cadernoId,
        finalidades,
        finalidadeId,
        setFinalidadeId,
        tipos,
        tipoId,
        setTipoId,
        area,
        setArea,
        areaMax,
        ats,
        setAts,
        preco,
        setPreco,
        precoMax,
        ufs,
        uf,
        setUf,
        cidades,
        setCidades,
        cidadeId,
        setCidadeId,
        regioes,
        setRegioes,
        regiaoId,
        setRegiaoId,
        bairros,
        setBairros,
        bairroId,
        setBairroId,
        setCallFetchData,
        setFilterSelected,
        filterLoading,
        setFilterLoading
      }

      resultado.data?.map((item, index) => data.push(<Box item={item} key={index} />))
      break
    case 9:
      if (cadernoId !== saveCaderno) {
        setSaveCaderno(cadernoId)
        setDestaques(false)
      }

      if (secoes === false && secoesLoading === false) {
        getSecoes(setSecoesLoading, cadernoId, setSecoes, setCallFetchData)
      }

      if (ufs === false && ufsLoading === false) {
        getEstados(setUfsLoading, setUfs, setCallFetchData)
      }

      if (niveisHierarquicos === false && niveisHierarquicosLoading === false) {
        getNiveisHierarquicos(setNiveisHierarquicosLoading, setNiveisHierarquicos, setCallFetchData)
      }

      if (perfisVaga === false && perfisVagaLoading === false) {
        getPerfis(setPerfisVagaLoading, setPerfisVaga, setCallFetchData)
      }

      if (escolaridades === false && escolaridadesLoading === false) {
        getEscolaridades(setEscolaridadesLoading, setEscolaridades, setCallFetchData)
      }

      topSearchProps = {
        cadernoId,
        secoes,
        secao,
        setSecao,
        ufs,
        uf,
        setUf,
        cidades,
        setCidades,
        cidadeId,
        setCidadeId,
        setRegioes,
        q,
        setQ,
        setCallFetchData
      }

      sideFilterProps = {
        cadernoId,
        secoes,
        secao,
        setSecao,
        perfisVaga,
        perfilVagaId,
        setPerfilVagaId,
        niveisHierarquicos,
        nivelHierarquicoId,
        setNivelHierarquicoId,
        escolaridades,
        escolaridadeId,
        setEscolaridadeId,
        setCallFetchData
      }

      filterProps = {
        secoes,
        secao,
        setSecao,
        perfisVaga,
        perfilVagaId,
        setPerfilVagaId,
        niveisHierarquicos,
        nivelHierarquicoId,
        setNivelHierarquicoId,
        escolaridades,
        escolaridadeId,
        setEscolaridadeId,
        ufs,
        uf,
        setUf,
        cidades,
        setCidades,
        cidadeId,
        setCidadeId,
        regioes,
        setRegioes,
        regiaoId,
        setRegiaoId,
        bairros,
        setBairros,
        bairroId,
        setBairroId,
        setCallFetchData,
        setFilterSelected,
        filterLoading,
        setFilterLoading
      }

      resultado.data?.map((item, index) =>
        data.push(<Box niveisHierarquicos={niveisHierarquicos} item={item} key={index} />)
      )
      break
    case 10:
      if (cadernoId !== saveCaderno) {
        setSaveCaderno(cadernoId)
        setDestaques(false)
      }

      if (retrancas === false && retrancasLoading === false) {
        getRetrancas(setRetrancasLoading, setRetrancas, setCallFetchData)
      }

      if (ufs === false && ufsLoading === false) {
        getEstados(setUfsLoading, setUfs, setCallFetchData)
      }

      topSearchProps = {
        cadernoId,
        retrancas,
        retrancaId,
        setRetrancaId,
        categorias,
        setCategorias,
        setCategoriasLoading,
        categoriaId,
        setCategoriaId,
        ufs,
        uf,
        setUf,
        cidades,
        setCidades,
        cidadeId,
        setCidadeId,
        setRegioes,
        q,
        setQ,
        setCallFetchData
      }

      sideFilterProps = {
        cadernoId,
        retrancas,
        retrancaId,
        setRetrancaId,
        categorias,
        setCategorias,
        setCategoriasLoading,
        categoriaId,
        setCategoriaId,
        setCallFetchData
      }

      filterProps = {
        cadernoId,
        retrancas,
        retrancaId,
        setRetrancaId,
        categorias,
        categoriaId,
        setCategoriaId,
        ufs,
        uf,
        setUf,
        cidades,
        setCidades,
        cidadeId,
        setCidadeId,
        regioes,
        setRegioes,
        regiaoId,
        setRegiaoId,
        bairros,
        setBairros,
        bairroId,
        setBairroId,
        setCallFetchData,
        setFilterSelected,
        filterLoading,
        setFilterLoading
      }

      resultado.data?.map((item, index) => data.push(<Box item={item} key={index} />))
      break
  }

  if (data.length === 0)
    data.push(<div className="no-result">Não encontramos resultados para a sua busca.</div>)

  if (destaques === false && destaquesLoading === false) {
    callDestaques(jornalId, cadernoId, setDestaquesLoading, setDestaques)
  }

  return (
    <div className={'App ' + editoria}>
      <Header logo={logo} to={config[editoria].header_url} />
      <div className={'top-search bg-' + editoriaConfig.slug}>
        <h2>{editoriaConfig.top_search_title}</h2>
        {topSearchSubtitle !== '' && <h4>{topSearchSubtitle}</h4>}
        <TopSearch {...topSearchProps} />
      </div>
      <div className="content">
        <div className="container content">
          <div className="row">
            <div className="col-12 text-center">
              <AdvertisingSlot id="bannerauto-01" />
            </div>
          </div>
        </div>

        <div className="box-content">
          <div className="container">
            {destaques !== false && destaques.length > 0 && (
              <div className="destaques">
                <h5>Oportunidades em Destaque</h5>
                <Destaques destaques={destaques} cor="azul" />
              </div>
            )}
            <div className="menu-search">
              <div className={'filter' + (showFilter ? ' active' : '')}>
                <img className="close-icon" src={CloseIcon} onClick={() => setShowFilter(false)} />
                <img className="logo" src={logo} />
                <div className="filter-group">
                  <h3>Busca por categorias</h3>
                  <SideFilter {...sideFilterProps} />
                  <div className="form-group">
                    <Localizacao
                      ufs={ufs}
                      uf={uf}
                      setUf={setUf}
                      cidades={cidades}
                      setCidades={setCidades}
                      cidadeId={cidadeId}
                      setCidadeId={setCidadeId}
                      regioes={regioes}
                      setRegioes={setRegioes}
                      regiaoId={regiaoId}
                      setRegiaoId={setRegiaoId}
                      bairros={bairros}
                      setBairros={setBairros}
                      bairroId={bairroId}
                      setBairroId={setBairroId}
                      setCallFetchData={setCallFetchData}
                    />
                  </div>
                </div>
              </div>
              <div className="contentList">
                <div
                  className={'btn-filter' + (filterSelected ? ' active' : '')}
                  onClick={() => setShowFilter(true)}
                >
                  Filtrar <FilterIcon color={filterSelected ? 'white' : '#979797'} />
                </div>
                <Filters {...filterProps} />
                {loadingData ? <>{loadHtml()}</> : data}
                <div className="ads">
                  <div className="row">
                    <div className="col-12 text-center">
                      <AdvertisingSlot id="bannerauto-02" />
                    </div>
                  </div>
                </div>

                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={(e, value) => pagination(value, setPage, setCallFetchData)}
                  shape="rounded"
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="row">
          <div className="col-12 text-center ">
            <AdvertisingSlot id="bannerauto-03" />
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="row d-none">
          <div className="col-12 text-center">
            <img src={require('../images/billboard_banner_970x250.png')} />
          </div>
        </div>
      </div>
      <BoxResultado />
      <div className="container content">
        <div className="row d-none">
          <div className="col-12 text-center">
            <img src={require('../images/billboard_banner_970x250.png')} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Editoria
