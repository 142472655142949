import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import SampleNextArrowIcon from '../../images/SampleNextArrowIcon'
import SamplePrevArrowIcon from '../../images/SamplePrevArrowIcon'
import AreaIcon from '../../images/AreaIcon'
import AndaresIcon from '../../images/AndaresIcon'
import VagasIcon from '../../images/VagasIcon'
import BanheirosIcon from '../../images/BanheirosIcon'
import { prefix } from '../../utils/variables'

const BoxDestaqueShow = (item) => {
  const [slideImages, setSlideImages] = React.useState([])
  item = item.item

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrowIcon />,
    prevArrow: <SamplePrevArrowIcon />
  }

  React.useEffect(() => {
    if (item.anexos !== undefined) {
      const anexo1 = item.anexos
      const anexo = [
        {
          url: anexo1?.url.lista
        }
      ]
      setSlideImages(anexo)
    }
  }, [item])

  // Tratamento de preço
  const real = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })

  let area, andares, garagem, banheiros
  if (item.caderno_id === '7') {
    area = item.atributos.find((i) => i.id === '52')
    area = area.valor !== '' ? area.valor : 0
    andares = item.atributos.find((i) => i.id === '73')
    andares = andares.valor !== '' ? andares.valor : 0
    garagem = 0
    const garagem1 = item.atributos.find((i) => i.id === '40')
    const garagem2 = item.atributos.find((i) => i.id === '41')
    const garagem3 = item.atributos.find((i) => i.id === '42')
    if (garagem1.valor === 't') {
      garagem = 1
    }
    if (garagem2.valor === 't') {
      garagem = 2
    }
    if (garagem3.valor === 't') {
      garagem = '3+'
    }
    banheiros = 0
    const banheiros1 = item.atributos.find((i) => i.id === '43')
    const banheiros2 = item.atributos.find((i) => i.id === '44')
    const banheiros3 = item.atributos.find((i) => i.id === '45')
    const banheiros4 = item.atributos.find((i) => i.id === '46')
    if (banheiros1.valor === 't') {
      banheiros = 1
    }
    if (banheiros2.valor === 't') {
      banheiros = 2
    }
    if (banheiros3.valor === 't') {
      banheiros = 4
    }
    if (banheiros4.valor === 't') {
      banheiros = '4+'
    }
  }

  let preco = false
  if (item.caderno_id === '2' || item.caderno_id === '7') {
    preco = real.format(item.preco)
  }

  let cabecalho = []
  if (item.caderno_id === '10') {
    if (item.cidade) {
      cabecalho.push(item.cidade.nome)
    }
    cabecalho.push(item.uf)
  } else {
    if (item.retranca && item.retranca.secao) {
      cabecalho.push(item.retranca.secao)
    }
  }
  cabecalho = cabecalho.join(' / ')

  return (
    <div className="box-detail">
      {item.caderno_id !== '10' && slideImages.length > 0 && (
        <div className="images">
          <Slider {...settings}>
            {slideImages.map((slideImage, index) => (
              <div key={index}>
                <div
                  className="photo"
                  style={{
                    backgroundImage: 'url(' + slideImage.url + ')',
                    backgroundSize: 'cover'
                  }}
                ></div>
              </div>
            ))}
          </Slider>
        </div>
      )}
      <div className="info">
        {cabecalho !== '' && <small>{cabecalho}</small>}
        <h4>{item.titulo}</h4>
        <div className="content">
          {item.caderno_id === '7' && <p className="descricao">{item.descricao_complementar}</p>}
          <p className="ano">{item.ano && item.ano_modelo && item.ano + '/' + item.ano_modelo}</p>
          <p className="cidade">
            {item.regiao && item.regiao.nome + ' - '}
            {item.cidade && item.cidade.nome + '/' + item.cidade.uf}
          </p>
        </div>
        <p className="price">
          {preco !== false && preco !== 'R$ 0,00' && (
            <>
              <b>Valor: </b>
              {preco}
            </>
          )}
        </p>
        {item.caderno_id === '7' && item.atributos && (
          <p className="atributos">
            <span>
              <AreaIcon /> {area}
            </span>
            <span>
              <AndaresIcon /> {andares}
            </span>
            <span>
              <VagasIcon /> {garagem}
            </span>
            <span>
              <BanheirosIcon /> {banheiros}
            </span>
          </p>
        )}
        <div className="clearfix"></div>
        <Link to={prefix + 'visualizar/' + item.id} className="btn btn-success btn-blue btn-mais">
          Ver mais
        </Link>
      </div>
    </div>
  )
}

export default BoxDestaqueShow
