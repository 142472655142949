import React from 'react'
import { useRoutes } from 'react-router-dom'
import Home from './pages/Home'
import View from './pages/View'
import Editoria from './pages/Editoria'

function App() {
  const routes = useRoutes([
    { path: process.env.PUBLIC_URL + '/', element: <Home /> },

    { path: process.env.PUBLIC_URL + '/visualizar/:id', element: <View /> },

    { path: process.env.PUBLIC_URL + '/autos', element: <Editoria editoria="autos" /> },
    { path: process.env.PUBLIC_URL + '/imoveis', element: <Editoria editoria="imoveis" /> },
    {
      path: process.env.PUBLIC_URL + '/carreiras-e-empregos',
      element: <Editoria editoria="carreiras" />
    },
    {
      path: process.env.PUBLIC_URL + '/oportunidades-e-leiloes',
      element: <Editoria editoria="oportunidades" />
    }
  ])

  return routes
}

export default App
