import React from 'react'

import { real } from '../api/api'
import { autosAtributos, imoveisAtributos } from '../utils/variables'

function Filters(props) {
  const {
    cadernoId,
    tipos,
    tipoId,
    setTipoId,
    marcas,
    setMarcas,
    marcaId,
    setMarcaId,
    modelos,
    setModelos,
    modeloId,
    setModeloId,
    ano,
    setAno,
    secoes,
    secao,
    setSecao,
    perfisVaga,
    perfilVagaId,
    setPerfilVagaId,
    niveisHierarquicos,
    nivelHierarquicoId,
    setNivelHierarquicoId,
    escolaridades,
    escolaridadeId,
    setEscolaridadeId,
    finalidades,
    finalidadeId,
    setFinalidadeId,
    area,
    setArea,
    areaMax,
    retrancas,
    retrancaId,
    setRetrancaId,
    categorias,
    setCategorias,
    categoriaId,
    setCategoriaId,
    preco,
    setPreco,
    precoMax,
    ufs,
    uf,
    setUf,
    cidades,
    setCidades,
    cidadeId,
    setCidadeId,
    regioes,
    setRegioes,
    regiaoId,
    setRegiaoId,
    bairros,
    setBairros,
    bairroId,
    setBairroId,
    ats,
    setAts,
    setCallFetchData,
    setFilterSelected,
    filterLoading,
    setFilterLoading
  } = props

  const filters = []

  if (tipos && tipoId && tipos !== false && tipoId !== '') {
    if (cadernoId === 2) {
      let result = tipos.servicos.find((i) => i.id === tipoId)
      if (result === undefined) {
        result = tipos.veiculos.find((i) => i.id === tipoId)
      }

      filters.push({
        id: result.id,
        title: 'Tipo: ',
        data: result.nome,
        action: () => {
          setTipoId('')
          setMarcas(false)
          setMarcaId('')
          setModelos(false)
          setModeloId('')
          setCallFetchData(true)
        }
      })
    } else if (cadernoId === 7) {
      let result
      let find = tipos.find((i) => i.id === tipoId)
      if (find !== undefined) {
        result = find
      } else {
        tipos.map((tipo) => {
          find = tipo.subtipos.find((i) => i.id === tipoId)
          if (find !== undefined) {
            result = find
          }
        })
      }

      filters.push({
        title: 'Tipo: ',
        data: result.nome,
        action: () => {
          setTipoId('')
          setCallFetchData(true)
        }
      })
    }
  }

  if (marcas && marcaId && marcas !== false && marcaId !== '') {
    const result = marcas.find((i) => i.id === marcaId)

    filters.push({
      title: 'Marca: ',
      data: result.nome,
      action: () => {
        setMarcaId('')
        setModelos(false)
        setModeloId('')
        setCallFetchData(true)
      }
    })
  }

  if (modelos && modeloId && modelos !== false && modeloId !== '') {
    const result = modelos.find((i) => i.id === modeloId)

    filters.push({
      title: 'Marca: ',
      data: result.nome,
      action: () => {
        setModeloId('')
        setCallFetchData(true)
      }
    })
  }

  if (secoes && secao && secoes !== false && secao !== '') {
    let result
    Object.keys(secoes).map((key) => {
      const find = secoes[key].find((i) => i.secao === secao)
      if (find !== undefined) {
        result = find
      }
    })

    filters.push({
      title: 'Seção: ',
      data: result.secao,
      action: () => {
        setSecao('')
        setCallFetchData(true)
      }
    })
  }

  if (perfisVaga && perfilVagaId && perfisVaga !== false && perfilVagaId !== '') {
    const result = perfisVaga.find((i) => i.id === perfilVagaId)

    filters.push({
      title: 'Perfil da vaga: ',
      data: result.descricao,
      action: () => {
        setPerfilVagaId('')
        setCallFetchData(true)
      }
    })
  }

  if (
    niveisHierarquicos &&
    nivelHierarquicoId &&
    niveisHierarquicos !== false &&
    nivelHierarquicoId !== ''
  ) {
    const result = niveisHierarquicos.find((i) => i.id === nivelHierarquicoId)

    filters.push({
      title: 'Nível hierárquico: ',
      data: result.descricao,
      action: () => {
        setNivelHierarquicoId('')
        setCallFetchData(true)
      }
    })
  }

  if (escolaridades && escolaridadeId && escolaridades !== false && escolaridadeId !== '') {
    const result = escolaridades.find((i) => i.id === escolaridadeId)

    filters.push({
      title: 'Nível hierárquico: ',
      data: result.descricao,
      action: () => {
        setEscolaridadeId('')
        setCallFetchData(true)
      }
    })
  }

  if (finalidades && finalidadeId && finalidades !== false && finalidadeId !== '') {
    const result = finalidades.find((i) => i.id === finalidadeId)

    filters.push({
      title: 'Finalidade: ',
      data: result.nome,
      action: () => {
        setFinalidadeId('')
        setCallFetchData(true)
      }
    })
  }

  if (area && (area[0] > 0 || area[1] < areaMax)) {
    filters.push({
      title: 'Área: ',
      data: 'de ' + area[0] + ' à ' + area[1],
      action: () => {
        setArea([0, areaMax])
        setCallFetchData(true)
      }
    })
  }

  if (ano && (ano[0] > 1990 || ano[1] < new Date().getFullYear())) {
    filters.push({
      title: 'Ano: ',
      data: 'de ' + ano[0] + ' à ' + ano[1],
      action: () => {
        setAno([1990, new Date().getFullYear()])
        setCallFetchData(true)
      }
    })
  }

  if (retrancas && retrancaId && retrancas !== false && retrancaId !== '') {
    const result = retrancas.find((i) => i.id === retrancaId)

    filters.push({
      title: 'Seção: ',
      data: result.descricao,
      action: () => {
        setRetrancaId('')
        setCategorias(false)
        setCategoriaId('')
        setCallFetchData(true)
      }
    })
  }

  if (categorias && categoriaId && categorias !== false && categoriaId !== '') {
    const result = categorias.find((i) => i.id === categoriaId)

    filters.push({
      title: 'Categoria: ',
      data: result.nome,
      action: () => {
        setCategoriaId('')
        setCallFetchData(true)
      }
    })
  }

  if (preco && (preco[0] > 0 || preco[1] < precoMax)) {
    filters.push({
      title: 'Preço: ',
      data: 'de ' + real(preco[0]) + ' à ' + real(preco[1]),
      action: () => {
        setPreco([0, precoMax])
        setCallFetchData(true)
      }
    })
  }

  if (ufs && uf && ufs !== false && uf !== '') {
    const result = ufs.find((i) => i.uf === uf)

    filters.push({
      title: 'Estado: ',
      data: result.uf,
      action: () => {
        setUf('')
        setCidades(false)
        setRegioes(false)
        setBairros(false)
        setCallFetchData(true)
      }
    })
  }

  if (cidades && cidadeId && cidades !== false && cidadeId !== '') {
    const result = cidades.find((i) => i.id === cidadeId)

    filters.push({
      title: 'Cidade: ',
      data: result.nome,
      action: () => {
        setCidadeId('')
        setRegioes(false)
        setBairros(false)
        setCallFetchData(true)
      }
    })
  }

  if (regioes && regiaoId && regioes !== false && regiaoId !== '') {
    const result = regioes.find((i) => i.id === regiaoId)

    filters.push({
      title: 'Região: ',
      data: result.nome,
      action: () => {
        setRegiaoId('')
        setBairros(false)
        setCallFetchData(true)
      }
    })
  }

  if (bairros && bairroId && bairros !== false && bairroId !== '') {
    const result = bairros.find((i) => i.id === bairroId)

    filters.push({
      title: 'Bairro: ',
      data: result.nome,
      action: () => {
        setBairroId('')
        setCallFetchData(true)
      }
    })
  }

  if (ats && ats.length > 0) {
    let defaultAts
    const oldAts = ats
    if (cadernoId === 2) {
      defaultAts = autosAtributos.caracs.concat(autosAtributos.cambio, autosAtributos.combustivel)
    } else if (cadernoId === 7) {
      defaultAts = imoveisAtributos.caracs.concat(imoveisAtributos.garagens, imoveisAtributos.dorms)
    }

    for (let i = 0; i < ats.length; i++) {
      const at = ats[i]
      const result = defaultAts.find((i) => i.id === at)

      filters.push({
        title: result.tipo + ': ',
        data: result.descricao,
        action: () => {
          oldAts.splice(i, 1)
          setAts(oldAts)
          setCallFetchData(true)
        }
      })
    }
  }

  if (!filterLoading) {
    if (filters.length > 0) {
      setFilterSelected(true)
    } else {
      setFilterSelected(false)
    }
    setFilterLoading(true)
  }

  const limparFiltro = () => {
    setTipoId && setTipoId('')
    setMarcaId && setMarcaId('')
    setMarcas && setMarcas(false)
    setModeloId && setModeloId('')
    setModelos && setModelos(false)
    setAno && setAno([1990, new Date().getFullYear()])
    setSecao && setSecao('')
    setPerfilVagaId && setPerfilVagaId('')
    setNivelHierarquicoId && setNivelHierarquicoId('')
    setEscolaridadeId && setEscolaridadeId('')
    setFinalidadeId && setFinalidadeId('')
    setArea && setArea([0, areaMax])
    setRetrancaId && setRetrancaId('')
    setCategorias && setCategorias(false)
    setCategoriaId && setCategoriaId('')
    setPreco && setPreco([0, precoMax])
    setUf && setUf('')
    setCidadeId && setCidadeId('')
    setCidades && setCidades(false)
    setRegiaoId && setRegiaoId('')
    setRegioes && setRegioes(false)
    setBairroId && setBairroId('')
    setBairros && setBairros(false)
    setCallFetchData && setCallFetchData(true)
    setAts && setAts([])
  }

  return (
    filters.length > 0 && (
      <ul className="clear-filter">
        <li>Você filtrou por: </li>
        {filters.map((filter, index) => (
          <li className="item" key={'clear-filter-' + index} onClick={filter.action}>
            {filter.title + filter.data}
            <i className="fas fa-times"></i>
          </li>
        ))}
        <li className="item" onClick={() => limparFiltro()}>
          Limpar filtros
        </li>
      </ul>
    )
  )
}

export default Filters
