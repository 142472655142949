// eslint-disable-line no-use-before-define
export default {
  slots: [
    {
      id: 'bannerhome-01',
      path: '/118650305/estadao/classificados.home',
      sizes: [
        [300, 250],
        [970, 90]
      ],
      sizeMappingName: 'pageheader'
    },
    {
      id: 'bannerhome-02',
      path: '/118650305/estadao/classificados.home',
      sizes: [
        [300, 250],
        [970, 90]
      ],
      sizeMappingName: 'pagefooter'
    },
    {
      id: 'medialab-01',
      path: '/118650305/estadao.home/posicao_pme_home',
      sizes: ['fluid']
    },
    {
      id: 'medialab-02',
      path: '/118650305/estadao.home/posicao_pme_home',
      sizes: ['fluid']
    },
    {
      id: 'medialab-03',
      path: '/118650305/estadao.home/posicao_pme_home',
      sizes: ['fluid']
    },
    {
      id: 'medialab-04',
      path: '/118650305/estadao.home/posicao_pme_home',
      sizes: ['fluid']
    },
    {
      id: 'bannerauto-01',
      path: '/118650305/estadao/classificados',
      sizes: [
        [300, 250],
        [970, 90]
      ],
      sizeMappingName: 'header'
    },
    {
      id: 'bannerauto-02',
      path: '/118650305/estadao/classificados',
      sizes: [
        [300, 250],
        [728, 90]
      ],
      sizeMappingName: 'pageMid'
    },
    {
      id: 'bannerauto-03',
      path: '/118650305/estadao/classificados',
      sizes: [
        [300, 90],
        [970, 250]
      ],
      sizeMappingName: 'footerInterna'
    },
    {
      id: 'bannerAnuncioView-01',
      path: '/118650305/estadao/classificados',
      sizes: [
        [300, 250],
        [970, 90]
      ],
      sizeMappingName: 'bottomadview'
    }
  ],
  sizeMappings: {
    header: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [640, 60],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [1000, 250],
        sizes: [[970, 90]]
      }
    ],
    pageMid: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [640, 60],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [1000, 250],
        sizes: [[728, 90]]
      }
    ],
    footerInterna: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [640, 60],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [1000, 250],
        sizes: [[970, 250]]
      }
    ],
    pageheader: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [640, 60],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [1000, 250],
        sizes: [[970, 250]]
      }
    ],
    pagefooter: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [640, 60],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [1000, 250],
        sizes: [[970, 90]]
      }
    ],
    bottomadview: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [640, 60],
        sizes: [[300, 250]]
      },
      {
        viewPortSize: [1000, 250],
        sizes: [[970, 90]]
      }
    ]
  }
}
