import React from 'react'
import { Link } from 'react-router-dom'
import { prefix } from '../utils/variables'
function ButtonSegment({ icon, text, clicked, to, iconHover }) {
  return (
    <Link to={prefix + to} className="subpage-menu-item">
      {icon && <img className="icon" src={icon} />}
      {iconHover && <img className="icon iconHover" src={iconHover} />}
      <p>{text}</p>
    </Link>
  )
}

export default ButtonSegment
