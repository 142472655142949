import React from 'react'
import { AdvertisingSlot } from 'react-advertising'

function Medialab4Items() {
  return (
    <div className="container container--vitrine">
      <div className="row footer__text">
        <h2>Conteúdo criado em parceria com pequenas e médias empresas, clique já e saiba mais</h2>
        <p>Estadão Blue Studio Express</p>
      </div>
      <div className="row footer__ad">
        <div className="col-xs-12 col-sm-6 col-md-3">
          <AdvertisingSlot id="medialab-01" className="footer__ad-item" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <AdvertisingSlot id="medialab-02" className="footer__ad-item" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <AdvertisingSlot id="medialab-03" className="footer__ad-item" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <AdvertisingSlot id="medialab-04" className="footer__ad-item" />
        </div>
      </div>
    </div>
  )
}

export default Medialab4Items
