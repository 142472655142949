import React from 'react'

const FilterIcon = (props) => {
  const { className, style, onClick, width = 20, height = 20, color = 'white' } = props
  return (
    <svg
      className={className}
      style={{ ...style }}
      onClick={onClick}
      width={width}
      height={height}
      viewBox={'0 0 ' + width + ' ' + height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_634_64)">
        <path
          d="M20 3.04688C19.8547 3.4082 19.5879 3.53438 19.202 3.52852C18.1418 3.51172 17.0812 3.525 16.0207 3.51992C15.8859 3.51914 15.818 3.54648 15.7781 3.6918C15.434 4.93867 14.2789 5.81172 12.9867 5.81289C11.6937 5.81406 10.543 4.94766 10.193 3.69688C10.1496 3.54141 10.073 3.51992 9.93242 3.51992C6.88125 3.52344 3.83008 3.51875 0.778906 3.52773C0.399609 3.52891 0.141797 3.39883 0 3.04688V2.73438C0.160547 2.37109 0.447266 2.26641 0.830859 2.26758C3.84766 2.27656 6.86484 2.27266 9.88164 2.27266H10.1332C10.1543 2.20859 10.1719 2.16055 10.1859 2.11172C10.4055 1.35078 10.8555 0.759375 11.5473 0.383203C11.866 0.209766 12.2332 0.125 12.5781 0C12.8387 0 13.0988 0 13.3594 0C13.3898 0.0105469 13.4191 0.0257812 13.4504 0.0316406C14.4809 0.219531 15.2098 0.797266 15.6484 1.74258C15.7266 1.91094 15.7766 2.09219 15.8414 2.27305H16.0633C17.098 2.27305 18.1328 2.28359 19.1676 2.26758C19.5531 2.26172 19.8359 2.37656 20 2.73477V3.04727V3.04688ZM12.9898 1.24453C12.0684 1.24258 11.3262 1.98086 11.3262 2.9C11.3262 3.81562 12.0754 4.56563 12.9875 4.56328C13.8906 4.56055 14.6398 3.81484 14.6453 2.9125C14.6508 1.99766 13.9059 1.24688 12.9898 1.24492V1.24453Z"
          fill={color}
        />
        <path
          d="M-6.10354e-06 16.9532C0.139838 16.5992 0.398041 16.4711 0.777728 16.4723C3.82265 16.4813 6.86718 16.4758 9.9121 16.4813C10.0836 16.4813 10.1531 16.4391 10.2019 16.266C10.5437 15.0516 11.7121 14.186 12.9859 14.1871C14.2672 14.1879 15.4332 15.0621 15.7734 16.2895C15.8141 16.436 15.8687 16.4821 16.0203 16.4813C17.0809 16.4742 18.1414 16.4891 19.2016 16.4715C19.5891 16.4649 19.8535 16.5938 19.9996 16.9535V17.266C19.8355 17.625 19.5519 17.7387 19.1668 17.7332C18.1258 17.7176 17.0844 17.7278 16.043 17.7278H15.8344C15.8141 17.7875 15.7977 17.8301 15.7852 17.8739C15.5426 18.7145 15.0305 19.3371 14.2441 19.7141C13.9668 19.8469 13.6551 19.9074 13.359 20.0008H12.5777C12.5422 19.9883 12.5078 19.9707 12.4711 19.9641C11.9074 19.859 11.4144 19.6121 10.9957 19.2207C10.5617 18.8153 10.284 18.3176 10.1387 17.7282H9.88202C6.86523 17.7282 3.84843 17.7239 0.831635 17.7332C0.44726 17.7344 0.163666 17.6246 -0.000396729 17.2664V16.9539L-6.10354e-06 16.9532ZM12.9926 18.7555C13.9016 18.752 14.6426 18.0121 14.6449 17.1051C14.6477 16.1942 13.8934 15.4371 12.9844 15.4375C12.0707 15.4379 11.3262 16.186 11.3258 17.1035C11.3258 18.0207 12.0707 18.7594 12.9922 18.7559L12.9926 18.7555Z"
          fill={color}
        />
        <path
          d="M0 9.84373C0.151563 9.48709 0.421875 9.36365 0.805078 9.36951C1.85273 9.38552 2.90039 9.37498 3.94844 9.37498H4.17031C4.35547 8.62341 4.7543 8.0238 5.38789 7.59177C5.88516 7.25271 6.43984 7.09177 7.04258 7.09216C8.21133 7.09255 9.47695 7.85662 9.87031 9.37498H10.0969C13.1297 9.37498 16.1621 9.37927 19.1945 9.36951C19.5781 9.36834 19.8473 9.48865 20.0004 9.84373V10.1562C19.8473 10.5105 19.5781 10.6316 19.1949 10.6304C16.1625 10.6207 13.1305 10.625 10.098 10.625H9.87109C9.45586 12.191 8.1625 12.8976 7.04336 12.9105C6.02578 12.9222 4.60664 12.3058 4.15898 10.625H2.66055C2.04219 10.625 1.42383 10.6105 0.80625 10.6304C0.421875 10.6429 0.153125 10.5113 0.000390625 10.1562V9.84373H0ZM7.01836 11.6574C7.93711 11.6554 8.67617 10.9117 8.67383 9.99177C8.67148 9.08201 7.93633 8.34724 7.02305 8.34216C6.10742 8.3367 5.35508 9.08279 5.35469 9.99685C5.35391 10.9109 6.10391 11.6594 7.01836 11.6574Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_634_64">
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FilterIcon
