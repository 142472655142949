import React from 'react'

const AreaIcon = (props) => {
  const { className, style, onClick, width = 15, height = 15, color = '#206395' } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <g>
        <path
          d="M5.16408 8.99175H3.60181C3.46232 8.99175 3.37863 8.91329 3.37863 8.78251V8.36405C3.37863 8.25943 3.46232 8.15481 3.60181 8.15481H5.16408V6.48095H3.60181C3.46232 6.48095 3.37863 6.40249 3.37863 6.27171V5.85325C3.37863 5.74863 3.46232 5.64402 3.60181 5.64402H5.16408V3.97015H3.60181C3.46232 3.97015 3.37863 3.89169 3.37863 3.76091V3.34245C3.37863 3.23784 3.46232 3.13322 3.60181 3.13322H5.16408V2.29628C5.16408 1.85166 4.74562 1.45935 4.27135 1.45935H1.59317C1.09101 1.45935 0.700439 1.85166 0.700439 2.29628V14.0133C0.700439 14.0918 0.700439 14.1703 0.728337 14.2226L5.16408 10.0903V8.99175ZM14.0914 10.6656H13.1986V12.1302C13.1986 12.261 13.087 12.3395 12.9754 12.3395H12.5291C12.3896 12.3395 12.3059 12.261 12.3059 12.1302V10.6656H10.5205V12.1302C10.5205 12.261 10.4089 12.3395 10.2972 12.3395H9.85091C9.71142 12.3395 9.62773 12.261 9.62773 12.1302V10.6656H7.84227V12.1302C7.84227 12.261 7.73067 12.3395 7.61908 12.3395H7.17273C7.03323 12.3395 6.94954 12.261 6.94954 12.1302V10.6656H5.77783L1.36999 14.8241C1.42578 14.8503 1.50947 14.8503 1.59317 14.8503H14.0914C14.5656 14.8503 14.9841 14.4841 14.9841 14.0133V11.5025C14.9841 11.058 14.5656 10.6656 14.0914 10.6656Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default AreaIcon
