import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

import ButtonTop from './ButtonTop'

import LogoClassificados from '../images/logo-classificados.svg'
import CloseIcon from '../images/closeIcon.svg'
import { prefix } from '../utils/variables'

function Header(props) {
  const { logo, to } = props
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    if (menu) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [menu])

  const mobileMenuClasses = ['nav', 'mobile-menu']
  if (menu) mobileMenuClasses.push('active')
  return (
    <header>
      <div id="header" className="container">
        <div className="row">
          <div className="col-4 d-none d-sm-block">
            <Link to={prefix} onClick={() => setMenu(false)}>
              <img src={LogoClassificados} />
            </Link>
          </div>
          <div className="col-12 col-sm-4 text-center">
            <Link to={to} onClick={() => setMenu(false)}>
              <img className="logo-editoria" src={logo} />
            </Link>
          </div>

          <div className="col-4 d-block d-sm-none">
            <label className="hamb" htmlFor="side-menu" onClick={() => setMenu(!menu)}>
              <i className="fa fa-bars" aria-hidden="true"></i>
            </label>
            <nav className={mobileMenuClasses.join(' ')}>
              <img className="close-icon" src={CloseIcon} onClick={() => setMenu(false)} />
              <div className="logo">
                <Link to={prefix}>
                  <img src={LogoClassificados} />
                </Link>
              </div>
              <ul className="menu">
                <li>
                  <NavLink to={prefix + 'autos'} onClick={() => setMenu(false)}>
                    Autos e Serviços
                  </NavLink>
                </li>
                <li>
                  <NavLink to={prefix + 'carreiras-e-empregos'} onClick={() => setMenu(false)}>
                    Carreiras e Emprego
                  </NavLink>
                </li>
                <li>
                  <NavLink to={prefix + 'imoveis'} onClick={() => setMenu(false)}>
                    Imóveis
                  </NavLink>
                </li>
                <li>
                  <NavLink to={prefix + 'oportunidades-e-leiloes'} onClick={() => setMenu(false)}>
                    Oportunidades e Leilões
                  </NavLink>
                </li>
              </ul>
              <hr />
              <div className="col-4 align-items-end enter-menu">
                <ButtonTop bgColor="#6FDC46" text="Anuncie Aqui" />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
