import React from 'react'
import { getCidades, getRegioes, getBairros } from '../api/api'

function Localizacao(props) {
  const {
    ufs,
    uf,
    setUf,
    cidades,
    setCidades,
    cidadeId,
    setCidadeId,
    regioes,
    setRegioes,
    regiaoId,
    setRegiaoId,
    bairros,
    setBairros,
    bairroId,
    setBairroId,
    setCallFetchData
  } = props

  function handleEstadoChange(value) {
    setUf(value)
    getCidades(value, setCidades, setCallFetchData)
    setCidades(false)
    setCidadeId('')
    setRegioes(false)
    setRegiaoId('')
    setBairros(false)
    setBairroId('')
    setCallFetchData(true)
  }

  function handleCidadeChange(value) {
    setCidadeId(value)
    getRegioes(value, setRegioes, setCallFetchData)
    setRegioes(false)
    setRegiaoId('')
    setBairros(false)
    setBairroId('')
    setCallFetchData(true)
  }
  function handleRegiaoChange(value) {
    setRegiaoId(value)
    getBairros(value, setBairros, setCallFetchData)
    setBairros(false)
    setBairroId('')
    setCallFetchData(true)
  }
  function handleBairroChange(value) {
    setBairroId(value)
    setCallFetchData(true)
  }

  return (
    <>
      <div className="form-group">
        <h4>Localização:</h4>
        <hr />
        <label>Estado:</label>
        <select
          className="form-control"
          onChange={(e) => handleEstadoChange(e.target.value)}
          name="uf"
          value={uf}
        >
          <option value="">Todos</option>
          {ufs !== false &&
            ufs.map((estado, index) => (
              <option key={index} value={estado.uf}>
                {estado.uf}
              </option>
            ))}
        </select>
      </div>

      <div className="form-group">
        <label>Cidade:</label>
        <select
          className="form-control"
          onChange={(e) => handleCidadeChange(e.target.value)}
          name="uf"
          value={cidadeId}
        >
          <option value="">Todos</option>
          {cidades !== false &&
            cidades.map((cidade, index) => (
              <option key={index} value={cidade.id}>
                {cidade.nome}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group">
        <label>Região:</label>
        <select
          className="form-control"
          onChange={(e) => handleRegiaoChange(e.target.value)}
          name="uf"
          value={regiaoId}
        >
          <option value="">Todos</option>
          {regioes !== false &&
            regioes.map((regiao, index) => (
              <option key={index} value={regiao.id}>
                {regiao.nome}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group">
        <label>Bairro:</label>
        <select
          className="form-control"
          onChange={(e) => handleBairroChange(e.target.value)}
          name="uf"
          value={bairroId}
        >
          <option value="">Todos</option>
          {bairros !== false &&
            bairros.map((bairro, index) => (
              <option key={index} value={bairro.id}>
                {bairro.nome}
              </option>
            ))}
        </select>
      </div>
    </>
  )
}

export default Localizacao
