import React, { useState } from 'react'
import {
  changeTipo,
  tipoSelected,
  marcaSelected,
  changeQ,
  scrollToResults,
  finalidadeSelected,
  tipoImovelSelected,
  getCidades,
  getRegioes,
  changeSecao,
  getCategorias
} from '../api/api'

import { sendGTMGA4Event } from '../utils/sendEvents'

function TopSearch({
  cadernoId,
  tipos,
  tipoId,
  setTipoId,
  marcas,
  setMarcas,
  marcaId,
  setMarcaId,
  setModelos,
  setModeloId,
  finalidades,
  finalidadeId,
  setFinalidadeId,
  secoes,
  secao,
  setSecao,
  ufs,
  uf,
  setUf,
  cidades,
  setCidades,
  cidadeId,
  setCidadeId,
  setRegioes,
  retrancas,
  retrancaId,
  setRetrancaId,
  categorias,
  setCategorias,
  setCategoriasLoading,
  categoriaId,
  setCategoriaId,
  q,
  setQ,
  setCallFetchData
}) {
  const [tipoSelecao, setTipoSelecao] = useState(false)
  const [fieldInput, setFieldInput] = useState('')
  let tipoPai
  let subtipos

  function secaoSelected(value) {
    setSecao(value)
    setCallFetchData(true)
  }

  function ufSelected(value) {
    setUf(value)
    getCidades(value, setCidades)
    setCallFetchData(true)
  }

  function cidadeSelected(value) {
    setCidadeId(value)
    getRegioes(value, setRegioes)
    setCallFetchData(true)
  }

  const retrancaSelected = (value) => {
    console.log('value: ', value)
    setRetrancaId(value)
    if (value !== '') {
      getCategorias(setCategoriasLoading, value, setCategorias, setCallFetchData)
    } else {
      setCategorias(false)
      setCategoriaId('')
      setCallFetchData(true)
    }
  }

  const categoriaSelected = (value) => {
    setCategoriaId(value)
    setCallFetchData(true)
  }

  const handleOnChangeInputField = (e) => {
    setFieldInput(e.target.value)
  }

  const doSearch = () => {
    sendGTMGA4Event({ event: 'search', search_term: fieldInput })
    scrollToResults()
  }

  switch (cadernoId) {
    case 2:
      return (
        <div className="box-search">
          <div className="buttons-search">
            <label>O que você precisa?</label>
            <div>
              {Object.keys(tipos).map((key, index) => (
                <button
                  key={'header-tipo-' + index}
                  className={'btn btn-yellow ' + (tipoSelecao === key ? 'y-checked' : '')}
                  onClick={() =>
                    changeTipo(
                      key,
                      setTipoSelecao,
                      setTipoId,
                      setMarcas,
                      setMarcaId,
                      setModelos,
                      setModeloId,
                      setCallFetchData
                    )
                  }
                >
                  {key === 'veiculos' ? 'Veículos' : 'Serviços'}
                </button>
              ))}
            </div>
          </div>
          <div className="form-group">
            <label>Qual tipo?</label>
            <select
              name="tipo_id"
              className="form-control"
              placeholder="Selecione"
              onChange={(e) =>
                tipoSelected(
                  e.target.value,
                  tipoId,
                  setTipoId,
                  setMarcas,
                  setMarcaId,
                  setModelos,
                  setModeloId,
                  setCallFetchData
                )
              }
              value={tipoId}
            >
              <option value="">Selecione</option>
              {tipos !== false &&
                tipos[tipoSelecao]?.map((tipo, index) => (
                  <option key={'header-subtipo-' + index} value={tipo.id}>
                    {tipo.nome}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>Marcas</label>
            <select
              name="marca_id"
              className="form-control"
              placeholder="Selecione"
              onChange={(e) =>
                marcaSelected(e.target.value, setMarcaId, setModelos, setModeloId, setCallFetchData)
              }
              value={marcaId}
            >
              <option value="">Selecione</option>
              {marcas !== false &&
                marcas.map((marca, index) => (
                  <option key={index} value={marca.id}>
                    {marca.nome}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>Palavra chave?</label>
            <input
              name="q"
              type="text"
              className="form-control"
              onChange={(e) => {
                changeQ(e.target.value, setQ, setCallFetchData)
                handleOnChangeInputField(e)
              }}
              value={q}
            />
          </div>
          <div>
            <button className="btn btn-primary btn-blue btn-search btn-block" onClick={doSearch}>
              Buscar
            </button>
          </div>
        </div>
      )
    case 7:
      tipoPai = tipoId
      if (tipoPai !== '' && tipos.find((t) => t.id === tipoPai) === undefined) {
        tipos.map((tipo) => {
          const subtipo = tipo.subtipos.find((t) => t.id === tipoPai)
          if (subtipo !== undefined) {
            tipoPai = tipo.id
          }
        })
      }

      subtipos
      if (tipoId !== '') {
        const tipo = tipos.find((i) => i.id === tipoId)
        if (tipo === undefined) {
          let findSubtipo
          tipos.map((t) => {
            const subtipo = t.subtipos.find((i) => i.id === tipoId)
            if (subtipo !== undefined) {
              findSubtipo = t
            }
          })
          subtipos = findSubtipo.subtipos
        } else {
          subtipos = tipo.subtipos
        }
      }

      return (
        <div className="box-search">
          <div className="buttons-search">
            <label className="d-block w-100">O que você precisa?</label>
            <div className="btn-group btn-group-justified">
              {finalidades !== false &&
                finalidades.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={'btn btn-yellow ' + (finalidadeId === item.id ? 'y-checked' : '')}
                      onClick={() => finalidadeSelected(item.id, setFinalidadeId, setCallFetchData)}
                    >
                      {item.nome}
                    </button>
                  )
                })}
            </div>
          </div>
          <div className="form-group">
            <label>Qual tipo de imóvel?</label>
            <select
              name="tipo_id"
              className="form-control"
              placeholder=" - Selecione"
              onChange={(e) => tipoImovelSelected(e.target.value, setTipoId, setCallFetchData)}
              value={tipoPai}
            >
              <option value="">Selecione</option>
              {tipos !== false &&
                tipos.map((tipo, index) => (
                  <option key={index} value={tipo.id}>
                    {tipo.nome}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>Qual subtipo?</label>
            <select
              name="subtipo_id"
              className="form-control"
              placeholder=" - Selecione"
              onChange={(e) => tipoImovelSelected(e.target.value, setTipoId, setCallFetchData)}
              value={tipoId}
            >
              <option value="">Selecione</option>
              {subtipos !== undefined &&
                subtipos.map((subtipo, index) => (
                  <option key={index} value={subtipo.id}>
                    {subtipo.nome}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>Onde?</label>
            <input
              name="q"
              type="text"
              className="form-control"
              onChange={(e) => {
                changeQ(e.target.value, setQ, setCallFetchData)
                handleOnChangeInputField(e)
              }}
              value={q}
            />
          </div>
          <div>
            <button className="btn btn-primary btn-blue btn-search" onClick={doSearch}>
              Buscar
            </button>
          </div>
        </div>
      )
    case 9:
      return (
        <div className="box-search">
          <div className="buttons-search">
            <div className="form-group">
              <label>Você procura oportunidades?</label>
              <div>
                {Object.keys(secoes).map((key, index) => (
                  <button
                    key={index}
                    className={'btn btn-yellow ' + (tipoSelecao === key ? 'y-checked' : '')}
                    onClick={() => changeSecao(key, setTipoSelecao, setSecao, setCallFetchData)}
                  >
                    {key === 'para_voce' ? 'Para você' : 'Para sua empresa'}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div>
            <div className="form-group">
              <label>Tipo:</label>
              <select
                name="tipo_id"
                className="form-control"
                placeholder="Selecione"
                onChange={(e) => secaoSelected(e.target.value)}
                value={secao}
              >
                <option value="">Selecione</option>
                {secoes !== false &&
                  secoes[tipoSelecao]?.map((secao, index) => (
                    <option key={'searc-carrer-options-' + index} value={secao.secao}>
                      {secao.secao}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div>
            <div className="form-group">
              <label>Estado:</label>
              <select
                name="uf"
                className="form-control"
                onChange={(e) => ufSelected(e.target.value)}
                value={uf}
              >
                <option value="">Todos</option>
                {ufs !== false &&
                  ufs.map((uf, index) => (
                    <option key={'searc-carrer-options-' + index} value={uf.uf}>
                      {uf.uf}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div>
            <div className="form-group">
              <label>Cidade:</label>
              <select
                name="cidade"
                className="form-control"
                onChange={(e) => cidadeSelected(e.target.value)}
                value={cidadeId}
              >
                <option value="">Todos</option>
                {cidades !== false &&
                  cidades.map((cidade, index) => (
                    <option key={'searc-carrer-options-' + index} value={cidade.id}>
                      {cidade.nome}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div>
            <div className="form-group">
              <label>Buscar por palavra-chave</label>
              <div className="flex-busca">
                <input
                  name="q"
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    changeQ(e.target.value, setQ, setCallFetchData)
                    handleOnChangeInputField(e)
                  }}
                  value={q}
                />
                <button className="btn btn-primary btn-blue btn-search" onClick={doSearch}>
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    case 10:
      return (
        <div className="row box-search">
          <div className="form-group">
            <label>Seção:</label>
            <select
              name="retranca_id"
              className="form-control"
              placeholder="Selecione"
              onChange={(e) => retrancaSelected(e.target.value)}
              value={retrancaId}
            >
              <option value="">Selecione</option>
              {retrancas !== false &&
                retrancas.map((retranca, index) => (
                  <option key={'searc-carrer-options-' + index} value={retranca.id}>
                    {retranca.descricao}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>Categorias:</label>
            <select
              name="categoria_id"
              className="form-control"
              placeholder="Selecione"
              onChange={(e) => categoriaSelected(e.target.value)}
              value={categoriaId}
            >
              <option value="">Selecione</option>
              {categorias !== false &&
                categorias.map((categoria, index) => (
                  <option key={'searc-carrer-options-' + index} value={categoria.id}>
                    {categoria.nome}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>Estado:</label>
            <select
              name="uf"
              className="form-control"
              onChange={(e) => ufSelected(e.target.value)}
              value={uf}
            >
              <option value="">Todos</option>
              {ufs !== false &&
                ufs.map((uf, index) => (
                  <option key={'searc-carrer-options-' + index} value={uf.uf}>
                    {uf.uf}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>Cidade:</label>
            <select
              name="cidade"
              className="form-control"
              onChange={(e) => cidadeSelected(e.target.value)}
              value={cidadeId}
            >
              <option value="">Todos</option>
              {cidades !== false &&
                cidades.map((cidade, index) => (
                  <option key={'searc-carrer-options-' + index} value={cidade.id}>
                    {cidade.nome}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>Buscar por palavra-chave</label>
            <input
              name="q"
              type="text"
              className="form-control"
              onChange={(e) => {
                changeQ(e.target.value, setQ, setCallFetchData)
                handleOnChangeInputField(e)
              }}
              value={q}
            />
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-blue btn-search" onClick={doSearch}>
              Buscar
            </button>
          </div>
        </div>
      )
  }
}

export default TopSearch
