import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AdvertisingProvider } from 'react-advertising'
import ads from './adconfigs/ads'

const root = ReactDOM.createRoot(document.getElementById('root'))
console.log('root: ', root)
root.render(
  <BrowserRouter>
    <AdvertisingProvider config={ads}>
      <App />
    </AdvertisingProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
