import React, { useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.scss'
import '../responsive.scss'
import { homeButtons } from '../utils/variables'
import BoxResultado from '../components/BoxResultado'
import ButtonSegment from '../components/ButtonSegment'
import ButtonTop from '../components/ButtonTop'
import Footer from '../components/Footer'

import logo from '../images/logo.png'
import { AdvertisingSlot, logVersionInfo } from 'react-advertising'

function Home() {
  const refAnuncie = useRef(null)

  const anuncieClick = () => {
    console.log('refAnuncie: ', refAnuncie)
    refAnuncie.current?.scrollIntoView({ behavior: 'smooth' })
  }

  logVersionInfo()
  return (
    <div className="App">
      <div className="header content">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-sm-6">
              <img src={logo} className="logo" />
            </div>
            <div id="buttons_top" className="col-12 col-sm-6 align-items-end d-none d-sm-block">
              <ButtonTop onClick={anuncieClick} bgColor="#6FDC46" text="Anuncie Aqui" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 titleHome">
              <h1>Seja bem vindo ao</h1>
              <h2>melhor e maior site de classificados</h2>
            </div>
          </div>
          <div className="subpage-menu">
            {homeButtons.map((button, index) => (
              <ButtonSegment
                key={'home-button-' + button.slug}
                to={button.slug}
                icon={button.icon}
                iconHover={button.iconHover}
                text={button.text}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="row">
          <div className="col-12 text-center">
            <AdvertisingSlot id="bannerhome-01" />
          </div>
        </div>
      </div>
      <div ref={refAnuncie}>
        <BoxResultado />
      </div>
      <div className="container content">
        <div className="row">
          <div className="col-12 text-center">
            <AdvertisingSlot id="bannerhome-02" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home
