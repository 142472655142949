import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SampleNextArrowIcon from '../images/SampleNextArrowIcon'
import SamplePrevArrowIcon from '../images/SamplePrevArrowIcon'

import BoxDestaqueShow from './BoxDestaqueShow/BoxDestaqueShow'

function Destaques(props) {
  const { destaques } = props

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrowIcon />,
    prevArrow: <SamplePrevArrowIcon />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <>
      <div className="destaques">
        <Slider {...settings}>
          {destaques.map((item, index) => (
            <BoxDestaqueShow item={item} key={'auto-destaque-' + index} />
          ))}
        </Slider>
      </div>
    </>
  )
}

export default Destaques
