import React from 'react'

const SampleNextArrowIcon = (props) => {
  const { className, style, onClick, width = 11, height = 18, color = '' } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 11 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.91547 8.97439C7.11526 8.2507 6.33177 7.54224 5.54828 6.83378C3.88555 5.33191 2.22435 3.82764 0.560097 2.32817C0.212049 2.01561 0.0114271 1.63734 0.0661421 1.14927C0.122377 0.654796 0.372394 0.294956 0.814674 0.104217C1.26987 -0.0913307 1.69239 -0.00718107 2.06324 0.321403C2.6803 0.867975 3.28825 1.42496 3.89999 1.97715C6.08099 3.94705 8.26124 5.91855 10.446 7.88445C10.8511 8.2491 11.1087 8.68106 10.9552 9.24527C10.8815 9.51695 10.7211 9.80626 10.5197 9.98899C7.69281 12.5624 4.85295 15.1197 2.01384 17.6771C1.47885 18.1587 0.720443 18.0914 0.285762 17.5392C-0.158797 16.9742 -0.0805245 16.2072 0.479544 15.7015C2.88321 13.5289 5.28839 11.3578 7.69205 9.18676C7.76272 9.12345 7.8296 9.05533 7.91395 8.97519L7.91547 8.97439Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

export default SampleNextArrowIcon
