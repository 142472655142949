import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SampleNextArrowIcon from '../images/SampleNextArrowIcon'
import SamplePrevArrowIcon from '../images/SamplePrevArrowIcon'
import AreaIcon from '../images/AreaIcon'
import AndaresIcon from '../images/AndaresIcon'
import VagasIcon from '../images/VagasIcon'
import BanheirosIcon from '../images/BanheirosIcon'
import { prefix } from '../utils/variables'

const Box = (props) => {
  const { item, niveisHierarquicos } = props
  const slideImages = []

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrowIcon />,
    prevArrow: <SamplePrevArrowIcon />
  }

  // Tratamento de preço
  const real = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })

  if (item.anexos && (item.tem_imagem === '1' || item.anexos?.url.lista)) {
    const anexo = {
      url: item.anexos.url.lista
    }
    slideImages.push(anexo)
  }

  let cabecalho = []
  let localizacao = []

  let content
  let area,
    andares,
    garagem,
    banheiros,
    garagem1,
    garagem2,
    garagem3,
    banheiros1,
    banheiros2,
    banheiros3,
    banheiros4,
    hierarquia
  switch (item.caderno_id) {
    case '2':
      item.ano && cabecalho.push(item.ano)
      item.ano_modelo && cabecalho.push(item.ano_modelo)
      cabecalho = cabecalho.join(' / ')

      item.cidade && item.cidade.nome && localizacao.push(item.cidade.nome)
      item.cidade && item.cidade.uf && localizacao.push(item.cidade.uf)
      localizacao = localizacao.join(' / ')

      content = (
        <div className="info position-relative">
          <div className="row">
            <div className="col-12 col-sm-12">
              {item.retranca && item.retranca.secao && <small>{item.retranca.secao}</small>}
              <h4>{item.titulo}</h4>
              <p className="content">
                {item.descricao_complementar}
                <br />
                {cabecalho}
                <br />
                <br />
                {localizacao}
              </p>
              <p className="price">
                {item.preco && item.preco !== '0.00' && (
                  <>
                    <b>Valor: </b>
                    {real.format(item.preco)}
                  </>
                )}
              </p>
            </div>
            <div className="text-center result-actions align-text-bottom">
              <Link
                to={prefix + 'visualizar/' + item.id}
                className="btn btn-success btn-blue float-end btn-mais"
              >
                Ver mais
              </Link>
            </div>
          </div>
        </div>
      )
      break
    case '7':
      area = item.atributos.find((i) => i.id === '52')
      area = area.valor !== '' && area.valor !== null ? area.valor : 0
      andares = item.atributos.find((i) => i.id === '73')
      andares = andares.valor !== '' && andares.valor !== null ? andares.valor : 0
      garagem = 0
      garagem1 = item.atributos.find((i) => i.id === '40')
      garagem2 = item.atributos.find((i) => i.id === '41')
      garagem3 = item.atributos.find((i) => i.id === '42')
      if (garagem1.valor === 't') {
        garagem = 1
      }
      if (garagem2.valor === 't') {
        garagem = 2
      }
      if (garagem3.valor === 't') {
        garagem = '3+'
      }
      banheiros = 0
      banheiros1 = item.atributos.find((i) => i.id === '43')
      banheiros2 = item.atributos.find((i) => i.id === '44')
      banheiros3 = item.atributos.find((i) => i.id === '45')
      banheiros4 = item.atributos.find((i) => i.id === '46')
      if (banheiros1.valor === 't') {
        banheiros = 1
      }
      if (banheiros2.valor === 't') {
        banheiros = 2
      }
      if (banheiros3.valor === 't') {
        banheiros = 4
      }
      if (banheiros4.valor === 't') {
        banheiros = '4+'
      }

      content = (
        <div className="info position-relative">
          <div className="row">
            <div className="col-12 col-sm-12">
              {item.retranca.finalidade && <small>{item.retranca.finalidade}</small>}
              <h4>{item.titulo}</h4>
              <p className="content">{item.descricao_complementar}</p>
              <p className="cidade">
                {item.regiao && item.regiao.nome + ' - '}
                {item.cidade && item.cidade.nome + '/' + item.cidade.uf}
              </p>
              <p className="price">
                {item.preco && item.preco !== '0.00' && (
                  <>
                    <b>Valor: </b>
                    {real.format(item.preco)}
                  </>
                )}
              </p>
              {item.atributos && (
                <p className="atributos">
                  <span>
                    <AreaIcon /> {area}
                  </span>
                  <span>
                    <AndaresIcon /> {andares}
                  </span>
                  <span>
                    <VagasIcon /> {garagem}
                  </span>
                  <span>
                    <BanheirosIcon /> {banheiros}
                  </span>
                </p>
              )}
            </div>

            <div className="text-center result-actions align-text-bottom">
              <Link
                to={prefix + 'visualizar/' + item.id}
                className="btn btn-success btn-blue float-end btn-mais"
              >
                Ver mais
              </Link>
            </div>
          </div>
        </div>
      )
      break
    case '9':
      if (niveisHierarquicos && niveisHierarquicos !== false) {
        hierarquia = niveisHierarquicos.find((i) => i.id === item.nivel_hierarquico_id)
      }
      item.cidade && cabecalho.push(item.cidade.nome)
      item.uf && cabecalho.push(item.uf)
      cabecalho = cabecalho.join(' / ')

      content = (
        <div className="info position-relative">
          <div className="row">
            <div className="col-12 col-sm-12">
              <small>{cabecalho}</small>
              <h4>{item.titulo}</h4>
              <p className="content">
                {hierarquia && (
                  <span className="cargo">
                    {hierarquia.descricao}
                    <br />
                  </span>
                )}
                <br />
                {item.descricao_complementar}
              </p>
            </div>
            <div className="text-center result-actions align-text-bottom">
              <Link
                to={prefix + 'visualizar/' + item.id}
                className="btn btn-success btn-blue float-end btn-mais"
              >
                Ver mais
              </Link>
            </div>
          </div>
        </div>
      )
      break
    case '10':
      item.cidade && cabecalho.push(item.cidade.nome)

      cabecalho.push(item.uf)
      cabecalho = cabecalho.join(' / ')

      content = (
        <div className="info position-relative">
          <div className="row">
            <div className="col-12 col-sm-12">
              <small>{cabecalho}</small>
              <h4>{item.titulo}</h4>
              <p className="content">{item.descricao_complementar}</p>
            </div>
            <div className="text-center result-actions align-text-bottom">
              <Link
                to={prefix + 'visualizar/' + item.id}
                className="btn btn-success btn-blue float-end btn-mais"
              >
                Ver mais
              </Link>
            </div>
          </div>
        </div>
      )
      break
  }

  return (
    <div className="box-detail box-shadow">
      {slideImages.length > 0 && (
        <div className="images">
          <Slider {...settings}>
            {slideImages.map((slideImage, index) => (
              <div key={index}>
                <div
                  className="photo"
                  style={{
                    backgroundImage: 'url(' + slideImage.url + ')'
                  }}
                ></div>
              </div>
            ))}
          </Slider>
        </div>
      )}
      {content}
    </div>
  )
}

export default Box
