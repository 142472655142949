import React from 'react'
import { Link } from 'react-router-dom'
import { real } from '../api/api'
import { prefix } from '../utils/variables'

function BoxSimilar(props) {
  const { data, item } = props

  let imagem = ''
  React.useEffect(() => {
    if (item.anexos) {
      const imagens = item.anexos
      imagem = imagens[0].url.detalhe
    }
  }, [item])

  let cabecalho
  let localizacao
  if (item.caderno_id === '2') {
    cabecalho = item.retranca.tipo
    localizacao = item.uf
  }

  return (
    <div className="anuncio">
      <div className="box-shadow">
        {imagem !== '' && <img width="100%" src={imagem} />}
        <div className="info">
          <small>{cabecalho}</small>
          <h4>{item.titulo}</h4>
          <p className="localizacao">{localizacao}</p>
          {data.show_price && (
            <p className="valor">
              Valor: <span>{real(item.preco)}</span>
            </p>
          )}
          <Link to={prefix + 'visualizar/' + item.id} className="btn">
            Ver mais
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BoxSimilar
