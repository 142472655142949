import React from 'react'

const SamplePrevArrowIcon = (props) => {
  const { className, style, onClick, width = 11, height = 18, color = '' } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 11 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.08453 9.02562C3.88474 9.7493 4.66823 10.4578 5.45172 11.1662C7.11444 12.6681 8.77565 14.1724 10.4399 15.6718C10.788 15.9844 10.9886 16.3627 10.9339 16.8507C10.8776 17.3452 10.6276 17.705 10.1853 17.8958C9.73013 18.0913 9.30761 18.0072 8.93676 17.6786C8.3197 17.132 7.71175 16.575 7.10001 16.0229C4.919 14.053 2.73876 12.0814 0.553961 10.1156C0.148918 9.7509 -0.108698 9.31894 0.0448082 8.75473C0.118522 8.48305 0.278868 8.19374 0.480249 8.01101C3.30719 5.43764 6.14705 2.88029 8.98616 0.322944C9.52115 -0.158711 10.2796 -0.0913907 10.7142 0.460791C11.1588 1.02579 11.0805 1.79276 10.5205 2.29846C8.1168 4.47112 5.71161 6.64218 3.30795 8.81324C3.23728 8.87655 3.1704 8.94467 3.08605 9.02481L3.08453 9.02562Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

export default SamplePrevArrowIcon
