import React, { useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import { Checkbox, FormControlLabel } from '@mui/material'
import Slider from '@mui/material/Slider'

import {
  marcaSelected,
  modeloSelected,
  tipoSelected,
  realSemMoeda,
  finalidadeSelected,
  tipoImovelSelected,
  getCategorias
} from '../api/api'
import { autosAtributos, imoveisAtributos } from '../utils/variables'

function SideFilter(props) {
  const {
    cadernoId,
    showFilter,
    tipos,
    tipoId,
    setTipoId,
    marcas,
    setMarcas,
    marcaId,
    setMarcaId,
    modelos,
    setModelos,
    modeloId,
    setModeloId,
    ano,
    setAno,
    preco,
    setPreco,
    precoMax,
    finalidades,
    finalidadeId,
    setFinalidadeId,
    area,
    setArea,
    areaMax,
    ats,
    setAts,
    secoes,
    secao,
    setSecao,
    perfisVaga,
    perfilVagaId,
    setPerfilVagaId,
    niveisHierarquicos,
    nivelHierarquicoId,
    setNivelHierarquicoId,
    escolaridades,
    escolaridadeId,
    setEscolaridadeId,
    retrancas,
    retrancaId,
    setRetrancaId,
    categorias,
    setCategorias,
    setCategoriasLoading,
    categoriaId,
    setCategoriaId,
    anAt,
    setCallFetchData
  } = props

  function changeCommited() {
    setCallFetchData(true)
  }

  function areaChange(e, value) {
    setArea([value[0], value[1]])
  }

  function precoChange(e, value) {
    setPreco([value[0], value[1]])
  }

  function atSelected(value) {
    setAts((oldArr) => {
      if (!oldArr.includes(value)) {
        oldArr.push(value)
      } else {
        const index = oldArr.indexOf(value)
        oldArr.splice(index, 1)
      }
      return oldArr
    })

    setCallFetchData(true)
  }

  function inputedAreaMin(e) {
    setArea([e.target.value, area[1]])
    setCallFetchData(true)
  }

  function inputedAreaMax(e) {
    setArea([area[0], e.target.value])
    setCallFetchData(true)
  }

  function inputedPrecoMin(e) {
    let value = e.target.value
    value = value.replaceAll('.', '').trim()
    if (value === '') {
      value = 0
    }
    value = parseFloat(value)
    setPreco([value, preco[1]])
    setCallFetchData(true)
  }

  function inputedPrecoMax(e) {
    let value = e.target.value
    value = value.replaceAll('.', '').trim()
    if (value === '') {
      value = 0
    }
    value = parseFloat(value)
    setPreco([preco[0], value])
    setCallFetchData(true)
  }

  function changeAno(e, value) {
    setAno([value[0], value[1]])
    setCallFetchData(true)
  }

  function changePreco(e, value) {
    setPreco([value[0], value[1]])
    setCallFetchData(true)
  }

  const secaoSelected = (value, secao, setSecao, setCallFetchData) => {
    setSecao(secao === value ? '' : value)

    setCallFetchData(true)
  }

  const perfilVagaSelected = (value) => {
    setPerfilVagaId(value)

    setCallFetchData(true)
  }

  const nivelHierarquicoSelected = (value) => {
    setNivelHierarquicoId(value)

    setCallFetchData(true)
  }

  const escolaridadeSelected = (value) => {
    setEscolaridadeId(value)

    setCallFetchData(true)
  }

  const retrancaSelected = (value) => {
    setRetrancaId(value)
    setCategoriaId('')
    if (value !== '') {
      getCategorias(setCategoriasLoading, value, setCategorias, setCallFetchData)
    } else {
      setCategorias(false)
      setCallFetchData(true)
    }
  }

  const categoriaSelected = (value) => {
    setCategoriaId(value)
    setCallFetchData(true)
  }

  const maxAno = new Date().getFullYear()

  let realPrecoMin
  let realPrecoMax
  if (preco) {
    realPrecoMin = realSemMoeda(preco[0])
    realPrecoMax = realSemMoeda(preco[1])
  }

  useEffect(() => {
    if (showFilter) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [showFilter])

  const caracs = []
  if (anAt !== undefined && anAt !== false) {
    let oldCaracs = []
    if (cadernoId === 2) {
      oldCaracs = autosAtributos.caracs
    } else if (cadernoId === 7) {
      oldCaracs = imoveisAtributos.caracs
    }
    for (let i = 0; i < oldCaracs.length; i++) {
      const carac = oldCaracs[i]

      const inList = anAt.find((i) => parseInt(i.id) === carac.id)
      if (inList !== undefined) {
        caracs.push(carac)
      }
    }
  }

  switch (cadernoId) {
    case 2:
      return (
        <>
          <div className="filterItem">
            <hr />
            {tipos &&
              tipos !== false &&
              Object.keys(tipos).map((key, index) => (
                <Accordion key={'tipos-' + index} defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {key === 'servicos' ? 'Serviços' : 'Veículos'}
                    </Accordion.Header>
                    <Accordion.Body>
                      {tipos[key] &&
                        tipos[key].map((tipo, index) => (
                          <FormControlLabel
                            key={index}
                            className={
                              'checkboxSearchLabel' + (tipo.id === tipoId ? ' Active' : '')
                            }
                            control={
                              <Checkbox
                                className={'checkboxSearch' + (tipo.id === tipoId ? ' active' : '')}
                                checked={tipo.id === tipoId}
                                onChange={(e) =>
                                  tipoSelected(
                                    e.target.value,
                                    tipoId,
                                    setTipoId,
                                    setMarcas,
                                    setMarcaId,
                                    setModelos,
                                    setModeloId,
                                    setCallFetchData
                                  )
                                }
                                name="tipo_id"
                                value={tipo.id}
                              />
                            }
                            label={tipo.nome}
                          />
                        ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
          </div>
          <div className="form-group">
            <label>Marca:</label>
            <select
              className="form-control"
              onChange={(e) =>
                marcaSelected(e.target.value, setMarcaId, setModelos, setModeloId, setCallFetchData)
              }
              name="marca_id"
              value={marcaId}
            >
              <option value="">Todos</option>
              {marcas !== false &&
                marcas.map((marca, index) => (
                  <option key={index} value={marca.id}>
                    {marca.nome}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <label>Modelo:</label>
            <select
              className="form-control"
              onChange={(e) => modeloSelected(e.target.value, setModeloId, setCallFetchData)}
              name="modelo_id"
              value={modeloId}
            >
              <option value="">Todos</option>
              {modelos !== false &&
                modelos.map((modelo, index) => (
                  <option key={index} value={modelo.id}>
                    {modelo.nome}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <label>Ano:</label>
            <Slider
              getAriaLabel={() => 'Temperature range'}
              valueLabelDisplay="auto"
              min={1990}
              step={1}
              max={maxAno}
              value={ano}
              onChange={changeAno}
            />
            <div className="d-flex justify-content-between">
              <div>
                <input
                  name="ano_min"
                  type="text"
                  className="form-control"
                  disabled="true"
                  value={ano[0]}
                />
              </div>
              <div style={{ width: '20%' }}>&nbsp;</div>
              <div>
                <input
                  name="ano_max"
                  type="text"
                  className="form-control"
                  disabled="true"
                  value={ano[1]}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Faixa de preço:</label>
            <Slider
              getAriaLabel={() => 'Temperature range'}
              value={preco}
              valueLabelDisplay="auto"
              min={0}
              step={100}
              max={precoMax}
              onChange={changePreco}
            />
            <div className="d-flex justify-content-between">
              <div>
                <input
                  name="preco_min"
                  type="text"
                  className="form-control"
                  onChange={inputedPrecoMin}
                  value={realPrecoMin}
                />
              </div>
              <div style={{ width: '20%' }}>&nbsp;</div>
              <div>
                <input
                  name="preco_max"
                  type="text"
                  className="form-control"
                  onChange={inputedPrecoMax}
                  value={realPrecoMax}
                />
              </div>
            </div>
          </div>

          <div className="form-group combustivel">
            <label>Tipo de combustível:</label>
            <hr />
            <div className="buttons">
              {autosAtributos.combustivel.map((carac, index) => (
                <button
                  key={'imoveis-combustiveis-' + index}
                  className={ats.includes(carac.id) ? 'active' : ''}
                  onClick={() => atSelected(carac.id)}
                >
                  {carac.descricao}
                </button>
              ))}
            </div>
          </div>

          <div className="form-group cambio">
            <label>Tipo de câmbio:</label>
            <hr />
            <div className="buttons">
              {autosAtributos.cambio.map((carac, index) => (
                <button
                  key={'imoveis-cambios-' + index}
                  className={ats.includes(carac.id) ? 'active' : ''}
                  onClick={() => atSelected(carac.id)}
                >
                  {carac.descricao}
                </button>
              ))}
            </div>
          </div>

          <div className="form-group caracteristicas">
            <label>Características:</label>
            <hr />
            {caracs.map((carac, index) => (
              <FormControlLabel
                key={index}
                className={'checkboxSearchLabel' + (ats.includes(carac.id) ? ' Active' : '')}
                control={
                  <Checkbox
                    className={'checkboxSearch' + (ats.includes(carac.id) ? ' active' : '')}
                    checked={ats.includes(carac.id)}
                    onChange={(e) => atSelected(carac.id)}
                    name="carac_id"
                    value={carac.id}
                  />
                }
                label={carac.descricao}
              />
            ))}
          </div>
        </>
      )
    case 7:
      return (
        <>
          <div className="filterItem">
            <hr />
            <h4>Imóveis para:</h4>
            <hr />
            {finalidades !== false &&
              finalidades.map((fin, index) => (
                <FormControlLabel
                  key={index}
                  className={'checkboxSearchLabel' + (fin.id === finalidadeId ? ' Active' : '')}
                  control={
                    <Checkbox
                      className={'checkboxSearch' + (fin.id === finalidadeId ? ' active' : '')}
                      checked={fin.id === finalidadeId}
                      onChange={(e) =>
                        finalidadeSelected(e.target.value, setFinalidadeId, setCallFetchData)
                      }
                      name="finalidade_id"
                      value={fin.id}
                    />
                  }
                  label={fin.nome}
                />
              ))}
            <h4>Tipo de imóvel:</h4>
            <hr />
            {tipos &&
              tipos[0] &&
              tipos !== false &&
              tipos.map((tipo, index) => {
                if (tipo.subtipos.length > 0) {
                  return (
                    <Accordion key={'secao-' + index} defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{tipo.nome}</Accordion.Header>
                        <Accordion.Body>
                          {tipo.subtipos.map((sub, index) => (
                            <FormControlLabel
                              key={index}
                              className={
                                'checkboxSearchLabel' + (sub.id === tipoId ? ' Active' : '')
                              }
                              control={
                                <Checkbox
                                  className={
                                    'checkboxSearch' + (sub.id === tipoId ? ' active' : '')
                                  }
                                  checked={sub.id === tipoId}
                                  onChange={(e) =>
                                    tipoImovelSelected(e.target.value, setTipoId, setCallFetchData)
                                  }
                                  name="tipo_id"
                                  value={sub.id}
                                />
                              }
                              label={sub.nome}
                            />
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )
                }
              })}
          </div>
          <div className="form-group">
            <label>Área:</label>
            <hr />
            <Slider
              getAriaLabel={() => 'Temperature range'}
              valueLabelDisplay="auto"
              min={0}
              step={1}
              max={areaMax}
              value={area}
              onChange={areaChange}
              onChangeCommitted={changeCommited}
            />
            <div className="d-flex justify-content-between">
              <div>
                <input
                  name="ano_min"
                  type="text"
                  className="form-control"
                  onChange={(e) => inputedAreaMin(e)}
                  value={area[0]}
                />
              </div>
              <div style={{ width: '20%' }}>&nbsp;</div>
              <div>
                <input
                  name="ano_max"
                  type="text"
                  className="form-control"
                  onChange={(e) => inputedAreaMax(e)}
                  value={area[1]}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Faixa de preço:</label>
            <hr />
            <Slider
              getAriaLabel={() => 'Temperature range'}
              value={preco}
              valueLabelDisplay="auto"
              min={0}
              step={100}
              max={precoMax}
              onChange={precoChange}
              onChangeCommitted={changeCommited}
            />
            <div className="d-flex justify-content-between">
              <div>
                <input
                  name="preco_min"
                  type="text"
                  className="form-control"
                  onChange={inputedPrecoMin}
                  value={realPrecoMin}
                />
              </div>
              <div style={{ width: '20%' }}>&nbsp;</div>
              <div>
                <input
                  name="preco_max"
                  type="text"
                  className="form-control"
                  onChange={inputedPrecoMax}
                  value={realPrecoMax}
                />
              </div>
            </div>
          </div>

          <div className="form-group dorms">
            <label>Número de dormitórios:</label>
            <hr />
            <div className="buttons">
              {imoveisAtributos.dorms.map((carac, index) => (
                <button
                  key={'imoveis-dorms-' + index}
                  className={ats.includes(carac.id) ? 'active' : ''}
                  onClick={() => atSelected(carac.id)}
                >
                  {carac.descricao}
                </button>
              ))}
            </div>
          </div>

          <div className="form-group garagens">
            <label>Número de vagas na garagem:</label>
            <hr />
            <div className="buttons">
              {imoveisAtributos.garagens.map((carac, index) => (
                <button
                  key={'imoveis-garagens-' + index}
                  className={ats.includes(carac.id) ? 'active' : ''}
                  onClick={() => atSelected(carac.id)}
                >
                  {carac.descricao}
                </button>
              ))}
            </div>
          </div>

          <div className="form-group caracteristicas">
            <label>Características:</label>
            <hr />
            {caracs.map((carac, index) => (
              <FormControlLabel
                key={index}
                className={'checkboxSearchLabel' + (ats.includes(carac.id) ? ' Active' : '')}
                control={
                  <Checkbox
                    className={'checkboxSearch' + (ats.includes(carac.id) ? ' active' : '')}
                    checked={ats.includes(carac.id)}
                    onChange={(e) => atSelected(carac.id)}
                    name="carac_id"
                    value={carac.id}
                  />
                }
                label={carac.descricao}
              />
            ))}
          </div>
        </>
      )
    case 9:
      return (
        <>
          <div className="filterItem">
            <hr />
            {secoes !== false &&
              Object.keys(secoes).map((key, index) => (
                <Accordion key={'secao-' + index} defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {key === 'para_voce' ? 'Para você' : 'Para sua empresa'}:
                    </Accordion.Header>
                    <Accordion.Body>
                      {secoes[key].map((sec, index) => (
                        <FormControlLabel
                          key={index}
                          className={'checkboxSearchLabel' + (sec.secao === secao ? ' Active' : '')}
                          control={
                            <Checkbox
                              className={'checkboxSearch' + (sec.secao === secao ? ' active' : '')}
                              checked={sec.secao === secao}
                              onChange={(e) =>
                                secaoSelected(e.target.value, secao, setSecao, setCallFetchData)
                              }
                              name="secao_id"
                              value={sec.secao}
                            />
                          }
                          label={sec.secao}
                        />
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
          </div>

          <div className="form-group">
            <label>Perfil da vaga:</label>
            <select
              className="form-control"
              onChange={(e) => perfilVagaSelected(e.target.value)}
              name="nivel_cargo_id"
              value={perfilVagaId}
            >
              <option value="">Todos</option>
              {perfisVaga !== false &&
                perfisVaga.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.descricao}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <label>Nivel hierarquico:</label>
            <select
              className="form-control"
              onChange={(e) => nivelHierarquicoSelected(e.target.value)}
              name="nivel_hierarquico_id"
              value={nivelHierarquicoId}
            >
              <option value="">Todos</option>
              {niveisHierarquicos !== false &&
                niveisHierarquicos.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.descricao}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <label>Escolaridade:</label>
            <select
              className="form-control"
              onChange={(e) => escolaridadeSelected(e.target.value)}
              name="escolaridade_id"
              value={escolaridadeId}
            >
              <option value="">Todos</option>
              {escolaridades !== false &&
                escolaridades.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.descricao}
                  </option>
                ))}
            </select>
          </div>
        </>
      )
    case 10:
      return (
        <>
          <div className="form-group">
            <label>Seção:</label>
            <select
              className="form-control"
              onChange={(e) => retrancaSelected(e.target.value)}
              name="nivel_cargo_id"
              value={retrancaId}
            >
              <option value="">Todos</option>
              {retrancas !== false &&
                retrancas.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.descricao}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>Categorias:</label>
            <select
              className="form-control"
              onChange={(e) => categoriaSelected(e.target.value)}
              name="nivel_hierarquico_id"
              value={categoriaId}
            >
              <option value="">Todos</option>
              {categorias !== false &&
                categorias.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nome}
                  </option>
                ))}
            </select>
          </div>
        </>
      )
  }
}

export default SideFilter
